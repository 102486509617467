import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  constructor(public appservice:AppService) { }
  txt_search="";
  ngOnInit(): void {
  }

  search_data(data)
  {
    if (data == "") {
      this.appservice.Item_Master_Rows=this.appservice.Perment_Item_Master_Row;
  
     }
     else {
      this.appservice.Item_Master_Rows=this.appservice.Perment_Item_Master_Row;
      this.appservice.Item_Master_Rows=this.appservice.Perment_Item_Master_Row.filter(e => e.Item_Name.toLowerCase().includes(data.toLocaleLowerCase()));
     }
  }

  
}
