import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-customerwise-profit',
  templateUrl: './customerwise-profit.component.html',
  styleUrls: ['./customerwise-profit.component.scss']
})
export class CustomerwiseProfitComponent implements OnInit {

  constructor(public appservice: AppService) {

  }
 export_excel()
 {

  this.appservice.Excel_Data.Header=this.appservice.Customerwise_Profit_Export;
  this.appservice.Excel_Data.items=this.appservice.Customerwise_Profit_Row
  this.appservice.Excel_Data.Report_Name="Customerwise Profit"
  this.appservice.export_excel();

 }


 export_pdf(data)
{

 this.appservice.Excel_Data.Header=this.appservice.Customerwise_Profit_Export;
 this.appservice.Excel_Data.Total_Row= "Total Rows : "+this.appservice.length_of(data);
 this.appservice.Excel_Data.Total_Amount="Total Amount : "+this.appservice.sum_of(data,'Amount');
 this.appservice.Excel_Data.items=this.appservice.Customerwise_Profit_Row
 this.appservice.Excel_Data.Report_Name="Customerwise Profit"
 this.appservice.export_pdf();
 }

 ngOnInit(): void {
 }

}
