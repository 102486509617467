  import { Component, OnInit } from '@angular/core';
  import { ToastrService } from 'ngx-toastr';
  import { HttpClient } from '@angular/common/http';
  import { Router, ActivatedRoute } from '@angular/router';
  import { AppService } from '../app.service';
  import { Location } from '@angular/common';
  
  @Component({
    selector: 'app-paymode-select',
    templateUrl: './paymode-select.component.html',
    styleUrls: ['./paymode-select.component.scss']
  })
  export class PaymodeSelectComponent implements OnInit {

    constructor(private _location: Location, public appservice: AppService, private toastr: ToastrService, private http: HttpClient, private router: Router, private route: ActivatedRoute)  { 
  
      this.get_Pay_Mode();
      this. get_Banks();
    }
  
    public isload:boolean=false;
    public Pay_Mode_ = [];
    get_Pay_Mode() {
  
      this.Pay_Mode_ = this.appservice.Reference_Rows.filter(e => e.Ref_ID=="Pay_Mode");
      
    }


    public Banks_=[];
    get_Banks() {
      this.Banks_ =this.appservice.Bank_Master_Rows;
    }
  
    
    public rows = [];
    public add: any = {
  
      
        'ID': '',
        'LEDGER_ID': '',
        'BILL_NO': '',
        'BILL_DATE': '',
        'BILL_MODE': '',
        'CUSTOMER_ID': '',
        'CUSTOMER_NAME': '',
        'ADDR1': '',
        'ADDR2': '',
        'ADDR3': '',
        'ADDR4': '',
        'ADDR5': '',
        'PHONE': '',
        'GSTNO': '',
        'STATE': '',
        'STATECODE': '',
        'S_CUSTOMER_NAME': '',
        'S_ADDR1': '',
        'S_ADDR2': '',
        'S_ADDR3': '',
        'S_ADDR4': '',
        'S_ADDR5': '',
        'S_PHONE': '',
        'S_GSTNO': '',
        'S_STATE': '',
        'S_STATECODE': '',
        'TAX_GROSSAMT': '0',
        'TOTAL_DISC': '0',
        'TAX_YESNO': '',
        'TAXABLE_AMT': '0',
        'CGST_PER': '0',
        'SGST_PER': '0',
        'IGST_PER': '0',
        'CGST_AMT': '0',
        'SGST_AMT': '0',
        'IGST_AMT': '0',
        'TOTAL_TAX': '0',
        'BILL_AMT': '0',
        'Received_AMT':'0',
        'Received_Bank':'0',
        'items':[],
        'CREATED_BY': '',
        'CREATED_DATE': '',
        'STATUS': '',
      };
    
    public addValidation: boolean = false;
    headers;
    data;
    isadd = "0";
  
    Place_Order() {
  
  
  
  
  
    this.add.ID=0;
    this.add.LEDGER_ID=this.appservice.Selected_Customer.Ledger_Name;
    
    if(this.appservice.isEdit==true)
    {

     this.add.BILL_NO=this.appservice.Bill_No;
     
    }
    else
    {

     this.add.BILL_NO="0";
    }

    

    this.add.BILL_DATE=this.appservice.Bill_Date;
    this.add.Due_Date=this.appservice.Due_Date;
    this.add.Place_of_Supply=this.appservice.Place_of_Supply;
    
    this.add.Sales_Person=this.appservice.Sales_Person;

    this.add.BILL_MODE=this.appservice.Bill_Mode;
    this.add.CUSTOMER_ID=this.appservice.Selected_Customer.ID;
    this.add.CUSTOMER_NAME=this.appservice.Selected_Customer.Ledger_Name;
    this.add.ADDR1=this.appservice.Selected_Customer.Address1;
    this.add.ADDR2=this.appservice.Selected_Customer.Address2;
    this.add.ADDR3=this.appservice.Selected_Customer.Address3;
    this.add.ADDR4=this.appservice.Selected_Customer.Address4;
    
    this.add.Area=this.appservice.Selected_Customer.Area;
    
    this.add.PHONE=this.appservice.Selected_Customer.Phone_Number;
    this.add.GSTNO=this.appservice.Selected_Customer.GSTIN;
    this.add.STATE=this.appservice.Selected_Customer.State;
    
    this.add.TAX_GROSSAMT=this.appservice.C_Sub_Total;
    this.add.TOTAL_DISC="0";
       
    this.add.TAXABLE_AMT=this.appservice.C_Taxable
    this.add.CGST_PER="0";
    this.add.SGST_PER="0";
    this.add.IGST_PER="0";
    this.add.CGST_AMT=this.appservice.C_CGST;
    this.add.SGST_AMT=this.appservice.C_SGST;
    this.add.IGST_AMT=this.appservice.C_IGST;
    this.add.TOTAL_TAX=this.appservice.C_Tax;
    this.add.BILL_AMT=this.appservice.C_Total_Payable;

    

    this.add.Pay_Mode=this.appservice.Pay_Mode;
    this.add.Received_Bank=this.appservice.Receiving_Bank;
    this.add.Cheque_No=this.appservice.Cheque_No;
    this.add.Received_Bank=this.appservice.Receiving_Bank;


    this.add.CREATED_BY=this.appservice.CREATED_BY;
  
    this.add.Company_Name=this.appservice.Selected_Company.CM_Name;
    this.add.Company=this.appservice.Company; 
    this.add.items=this.appservice.cart_item;
        this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

        this.isload=true;
        this.http.post(this.appservice.Server_URL + 'api/Invoice/insert_Sales', this.add, { headers: this.headers })
          .subscribe(
            (val: string) => {
              this.isload=false;
              if (val == "True") {
                this.toastr.success("Invoice Saved Successfully", 'Msg');
                 this.appservice.Clear_Cart();
                 this.appservice.isEdit=false;
                 this.appservice.get_Sales_Details();
                 this.router.navigate(["/sales-details"]);
  
              }
              else {
                this.toastr.error(val, "Error", { timeOut: 3000 });
              }
            },
            response => {
              this.toastr.error('Error ', response, {
                timeOut: 3000
              });
            });
      
    }
    
  
  
    ngOnInit(): void {
    }
  
  }
  
