import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deleted-order-details',
  templateUrl: './deleted-order-details.component.html',
  styleUrls: ['./deleted-order-details.component.scss']
})
export class DeletedOrderDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
