<div class="row">
  <div class="col-sm-12 nopad ">
    <div class="card content ">

      <div class=" Head ">
        <div class="card-header  d-flex justify-content-between align-items-center  ">
          <h3>Item Master</h3>

          <div class="dropdown">

            <a (click)="Back()" style="font-size: 20px;font-weight: 900;cursor: pointer;color:black">
              <b>X</b>
            </a>


          </div>
        </div>

      </div>

      <div class="content_body">
 
          <form #addForm="ngForm" (ngSubmit)="addData(addForm)" novalidate>




            <div class="row">

              <div class="col-sm-6">

                <div class="form-table table-responsive">
                  <table class="table table-bordered">


                    <tbody>
                      <tr class="hidden">
                        <td>ID <span class="text-danger">*</span></td>
                        <td><input class="form-control" type="text" [(ngModel)]="add.ID" name="ID" #ID="ngModel"
                            required></td>
                      </tr>


                      <tr>
                        <td>Item&nbsp;Name<span class="text-danger">*</span></td>
                        <td>

                          <input class="form-control afocus" required type="text" [(ngModel)]="add.Item_Name"
                            name="Item_Name" #Item_Name="ngModel">
                          <div *ngIf="addForm.submitted && Item_Name.invalid" class="invalid-feedback d-block">
                            <div class="error">Item Name Should not entry</div>
                          </div>
                        </td>
                      </tr>


                      <tr>
                        <td>Short&nbsp;Name</td>
                        <td>

                          <input class="form-control afocus" required type="text" [(ngModel)]="add.Short_Name"
                            name="Short_Name" #Short_Name="ngModel">
                          
                        </td>
                      </tr>



                      <tr>
                        <td>Description </td>
                        <td>
                          <input class="form-control"  type="text" [(ngModel)]="add.Description"
                            name="Description" #Description="ngModel">
                         
                        </td>
                      </tr>

                      <tr>
                        <td>Bag Qty </td>
                        <td>
                          <input class="form-control"  type="text" [(ngModel)]="add.Bag_Qty"
                            name="Bag_Qty" #Bag_Qty="ngModel">
                         
                        </td>
                      </tr>


                      <tr>
                        <td>Category
                          <span class="bx bx-plus-circle add_icon"></span>
                        </td>
                        <td>
                          <p-dropdown [style]="{'width':'100%','height':'40px'}" [options]="Item_Category1" placeholder="Choose Category"
                            [(ngModel)]="add.Category" name="Category" #Category="ngModel" required filter="true">
                            <ng-template let-item pTemplate="selectedItem">
                              <span>{{item.label}}</span>
                            </ng-template>
                            <ng-template let-car pTemplate="item">
                              <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                                <div>{{car.label}}</div>
                              </div>
                            </ng-template>
                          </p-dropdown>

                          <div *ngIf="addForm.submitted && Category.invalid" class="invalid-feedback d-block">
                            <div class="error">Category Should not entry</div>
                          </div>
                        </td>
                      </tr>


                      <tr>
                        <td>HSN&nbsp;Code</td>
                        <td>
                          <input class="form-control"  type="text" [(ngModel)]="add.HSN_Code"
                            name="HSN_Code" #HSN_Code="ngModel">
                         
                        </td>
                      </tr>


                      <tr>
                        <td>GST %</td>
                        <td>
                          <input class="form-control" value="0" type="number" [(ngModel)]="add.GST_Per"
                            name="GST_Per" #GST_Per="ngModel">
                         
                        </td>
                      </tr>



                      <tr>
                        <td>Unit</td>
                        <td>
                          <p-dropdown [style]="{'width':'100%','height':'40px'}" [options]="Units"
                            [(ngModel)]="add.UOM" name="UOM" #UOM="ngModel" required filter="true">
                            <ng-template let-item pTemplate="selectedItem">
                              <span>{{item.label}}</span>
                            </ng-template>
                            <ng-template let-car pTemplate="item">
                              <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                                <div>{{car.label}}</div>
                              </div>
                            </ng-template>
                          </p-dropdown>

                          <div *ngIf="addForm.submitted && UOM.invalid" class="invalid-feedback d-block">
                            <div class="error">Unit Should not entry</div>
                          </div>
                        </td>
                      </tr>



                      <tr>
                        <td>Display Order</td>
                        <td>
                          <input class="form-control" type="Number" [(ngModel)]="add.Display_order" name="Display_order"
                            #Display_order="ngModel">
                        </td>
                      </tr>



                      <tr>
                        <td>Sales&nbsp;Rate</td>
                        <td>
                          <input class="form-control" type="Number" [(ngModel)]="add.Rate" name="Rate"
                            #Rate="ngModel">
                        </td>
                      </tr>



                      <tr>
                        <td>MRP</td>
                        <td>
                          <input class="form-control" required type="number" [(ngModel)]="add.compareAtPrice"
                            name="compareAtPrice" #compareAtPrice="ngModel">
                          <div *ngIf="addForm.submitted && compareAtPrice.invalid" class="invalid-feedback d-block">
                            <div class="error">Compare Price Should not entry</div>
                          </div>
                        </td>
                      </tr>



                      <tr *ngIf="appservice.img_visible" >
                        <td>Image</td>
                        <td>
                          <p-fileUpload name="myfile[]" url="{{server}}" (onUpload)="onUpload($event)"
                            multiple="multiple" maxFileSize="1000000">
                            <ng-template pTemplate="content">
                              <ul *ngIf="uploadedFiles.length">
                                <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                              </ul>
                            </ng-template>
                          </p-fileUpload>

                        </td>
                      </tr>



                    </tbody>
                  </table>

                  <div class="invoice-btn-box text-right">
            
                    <button class="btn btn-primary rounded-pil  sbtn"  [disabled]="btndisable" type="submit"> 
                      <span *ngIf="btndisable==true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                      <i   *ngIf="btndisable==false" class='bx bx-paper-plane'></i> Save 
                    
                    </button> &nbsp;&nbsp;

                    

                 <button class="btn btn-secondary rounded-pil " type="button" (click)="Back()">Cancel</button>  &nbsp;&nbsp;
                </div>
                </div>
              </div>
            </div>



          </form>
       
      </div>
    </div>
  </div>
</div>