<div class="row">

    <div class="col-md-12 nopad hbar" >
       &nbsp; Sales Return Details

    
       
       <a (click)="appservice.Open_Modal('Title','item_Master')" class="x pright45" >
           
            <li class="bx bx-calendar-week"></li>
        </a>

        <a (click)="appservice.back()" class="x pright15">
            <b>X</b>
        </a>


        </div>
</div>


<div class="row shade">

    <div class="col-md-5 nopad">

         <table style="width: 100%;">
                        
                        <tr *ngFor="let data of appservice.get_pos_field('Purchase','1TL')">
                            <td style="padding-left: 8px;width: 120px;" >{{data.Name}}  <span *ngIf="data.Validate=='True'" class="text-danger">*</span></td>

                            <td>
                                <select  *ngIf="data.Type=='Select'" class="select2-option form-control"  aria-hidden="true"
                                 required="data.Validate=='True'" [(ngModel)]="appservice.header_Row[data.Field]"
                                    name="{{data.Field}}" #{{data.Field}}="ngModel">
                                   <option *ngFor="let row of appservice.get_ref(data.Options)" value={{row.label}}>
                                       {{row[data.Store_Value]}}
                                   </option>
                                 </select>


                                 <input *ngIf="(data.Field=='Supplier_Name' || data.Field=='Customer_Name' )" class="form-control"   (focus)="get_customers()"  [readonly]="data.IsEdit=='False'"  required="data.Validate=='True'" type="{{data.Type}}" [(ngModel)]="appservice.header_Row[data.Field]"
                                 name="{{data.Field}}" #{{data.Field}}="ngModel"   >

                        <textarea   *ngIf="(data.Type=='TextArea')" class="form-control"   [readonly]="data.IsEdit=='False'"  required="data.Validate=='True'"  [(ngModel)]="appservice.header_Row[data.Field]"
                        name="{{data.Field}}" #{{data.Field}}="ngModel" rows="3"></textarea>
                                 
                                <input *ngIf="!(data.Type=='Select'|| data.Type=='TextArea' || data.Field=='Supplier_Name' || data.Field=='Customer_Name')" class="form-control"   [readonly]="data.IsEdit=='False'"  required="data.Validate=='True'" type="{{data.Type}}" [(ngModel)]="appservice.header_Row[data.Field]"
                                    name="{{data.Field}}" #{{data.Field}}="ngModel"   >
                                
                                    <div *ngIf='addForm.submitted && (data.Validate=="True") && (appservice.header_Row[data.Field]=="")' class="invalid-feedback d-block">
                                        <div class="error"> {{data.Name}} Should not entry</div>
                                    </div>  
                                </td>
                        </tr>

                    </table>

    </div>


    <div class=" col-md-4 nopad" >

            <table style="width: 100%;">
                        
                    <tr *ngFor="let data of appservice.get_pos_field('Purchase','2TM')">
                        <td style="padding-left: 8px;width: 120px;">{{data.Name}}  <span *ngIf="data.Validate=='True'" class="text-danger">*</span></td>

                        <td>

                            <select  *ngIf="data.Field=='Accounts_ID' " class="select2-option form-control"  aria-hidden="true"
                            required="data.Validate=='True'" [(ngModel)]="appservice.header_Row[data.Field]"
                               name="{{data.Field}}" #{{data.Field}}="ngModel">
                              <option *ngFor="let row of appservice.get_Ledger_group(13)" value={{row.value}}>
                                  {{row.label}}
                              </option>
                            </select>


                            <select  *ngIf="(data.Type=='Select'  && data.Field!='Accounts_ID')" class="select2-option form-control"  aria-hidden="true"
                             required="data.Validate=='True'" [(ngModel)]="appservice.header_Row[data.Field]"
                                name="{{data.Field}}" #{{data.Field}}="ngModel">
                               <option *ngFor="let row of appservice.get_ref(data.Options)" value={{row.label}}>
                                   {{row[data.Store_Value]}}
                               </option>
                             </select>

                            
                            <input *ngIf="(data.Type!='Select'  && data.Field!='Accounts_ID')" class="form-control"   [readonly]="data.IsEdit=='False'"  required="data.Validate=='True'" type="{{data.Type}}" [(ngModel)]="appservice.header_Row[data.Field]"
                            [readonly]="data.IsEdit=='False'"  name="{{data.Field}}" #{{data.Field}}="ngModel"   >
                            
                                <div *ngIf='addForm.submitted && (data.Validate=="True") && (appservice.header_Row[data.Field]=="")' class="invalid-feedback d-block">
                                    <div class="error"> {{data.Name}} Should not entry</div>
                                </div>  
                            </td>
                    </tr>
<tr>
     <td style="padding-left: 8px;width: 120px;">Return Type</td>
     <td>
        <select style="height: 30px;" placeholder="Select Area" class="area form-control form-control-sm"
        [(ngModel)]="appservice.header_Row.Return_Type" name="Return_Type" #Return_Type="ngModel" style="width:100%"
        aria-hidden="true">
  
        <option *ngFor="let data of this.appservice.get_ref('Return_Type')" value="{{data.label}}">
          {{data.label}}
        </option>
      </select>
     </td>

</tr>
                </table>     

    </div>

    <div class=" col-md-3 nopad" >

            <table style="width: 100%;">
                        
                    <tr *ngFor="let data of appservice.get_pos_field('Purchase','3TR')">
                        <td style="width: 120px; padding-left: 8px;" >{{data.Name}}  <span *ngIf="data.Validate=='True'" class="text-danger">*</span></td>

                        <td>


                            <select  *ngIf="data.Field=='Accounts_ID' " class="select2-option form-control"  aria-hidden="true"
                            required="data.Validate=='True'" [(ngModel)]="appservice.header_Row[data.Field]"
                               name="{{data.Field}}" #{{data.Field}}="ngModel">
                              <option *ngFor="let row of appservice.get_Ledger_group(13)" value={{row.value}}>
                                  {{row.label}}
                              </option>
                            </select>


                            <select  *ngIf="(data.Type=='Select' && data.Field!='Accounts_ID')" class="select2-option form-control"  aria-hidden="true"
                             required="data.Validate=='True'" [(ngModel)]="appservice.header_Row[data.Field]"
                                name="{{data.Field}}" #{{data.Field}}="ngModel">
                               <option *ngFor="let row of appservice.get_ref(data.Options)" value={{row.label}}>
                                   {{row[data.Store_Value]}}
                               </option>
                             </select>

                            
                            <input *ngIf="(data.Type!='Select' && data.Field!='Accounts_ID')" class="form-control"   [readonly]="data.IsEdit=='False'"  required="data.Validate=='True'" type="{{data.Type}}" [(ngModel)]="appservice.header_Row[data.Field]"
                                name="{{data.Field}}" #{{data.Field}}="ngModel"   >
                            
                                <div *ngIf='addForm.submitted && (data.Validate=="True") && (appservice.header_Row[data.Field]=="")' class="invalid-feedback d-block">
                                    <div class="error"> {{data.Name}} Should not entry</div>
                                </div>  
                            </td>
                    </tr>

                </table>
         
    </div>

</div>



<div class="row">
    <div class="col-md-12 nopad" >


            <div class="spin_block" *ngIf="new_load">
                    <div class="d-flex justify-content-center">
                      <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>

           
            <div class="entry_table1">

                    <div class="header">
                            <div style="width:30px">#</div>
                            <div *ngFor="let data of rs" [style.width]="data.Width">{{data.Name}}</div>
                            <div style="width: 40px;"></div>
                    </div>

                    <div class="body" *ngFor="let rowData of appservice.Details_Row;index as i">
                            <div class="row_hover" style="width:30px">{{i+1}}</div>
                            <div *ngFor="let data of rs" class="in cell row_hover" [style.width]="data.Width">

                                    <label 
                                    [style.background-color]="(rowData.Sales_Qty==rowData.Qty)?'#ffc1073b':''"
                                    [style.text-align]="data.Align"  style=" width: 100%; padding-right: 5px;"  *ngIf="(data.Type=='Label')">{{rowData[data.Field]}}</label>


                                    <input    
                                    [style.background-color]="(rowData.Sales_Qty==rowData.Qty)?'#ffc1073b':''"

                                    *ngIf="!(data.Type=='Label')"
                                    [(ngModel)]="rowData[data.Field]"
                                    (change)="calc_details_Row(i)"
                                    (keyup)="calc_details_Row(i)" 
                                    [style.text-align]="data.Align" type="{{data.Type}}" class="in tbtext"
                                            style="width: 100%;" value="{{rowData[data.Field]}}">

                                            <span *ngIf="data.Field=='Pcs'" style="font-size: 10px;
                            position: absolute;
                            right: 25px;
                            background: bottom;
                            padding-top: 23px;
                            font-family: monospace;
                            color: #005b9f;" > <span *ngIf="rowData.Sales_Qty!=0">{{rowData.Sales_Qty}}</span> </span>
                                           
                            </div>
                            

                            <div  class="del_bx row_hover">
                                 <li (click)="del_row(i)" class="bx bx-trash"></li>
                            </div>
                            <div class="del_bx row_hover">
                                    <li (click)="Open_Customer_Module1(rowData)" class="bx bxs-hand-up"></li>
                                     </div>
                    </div>


                    <div class="body">
                            <div style="width:30px"></div>
                            <div *ngFor="let data of rs ; index as i" class="cell" [style.width]="data.Width">



                                    <input *ngIf="data.Field=='Item_Code'" placeholder="Code"
                                            [(ngModel)]="appservice.Sales_Temp_Row.Item_Code" name="ID"
                                            #ID="ngModel"
                                            (keyup.enter)="get_by_Code(appservice.Sales_Temp_Row.Item_Code,'Code')"
                                            class="code in tbtext" type="text">


                                   


                                   
                                    <label [style.text-align]="data.Align"  style=" width: 100%; padding-right: 5px;"  *ngIf="(data.Type=='Label') ">{{appservice.Sales_Temp_Row[data.Field]}}</label>

                                    <input *ngIf="!((data.Field=='Item_Name') || (data.Type=='Label') || (data.Field=='Item_Code')) "
                                    [readonly]="data.IsEdit=='False'"   class="in tbtext {{data.Class}}" [(ngModel)]="appservice.Sales_Temp_Row[data.Field]"
                                     (keyup)="calc_tempdata()"  (keyup.enter)="out()"   [style.text-align]="data.Align"
                                            type="{{data.Type}}"   >

                            </div>

                            <div   class="add_bx">
                                    <li *ngIf="(appservice.Sales_Temp_Row.Qty+appservice.Sales_Temp_Row.Free)>0" tabindex="0"
                                    (keyup.enter)="add_data(appservice.Sales_Temp_Row)"
                                            (click)="add_data(appservice.Sales_Temp_Row)"
                                   class="bx bx-plus-circle in"></li>
                            </div>

                           



                    </div>
            </div>
    </div>
    <div>
            
    </div>
</div>

<div class="row">



            <div class=" col-md-5 nopad" >

                    <table style="width: 100%;">
                                
                            <tr *ngFor="let data of appservice.get_pos_field('Purchase','4BL')">
                                <td style="padding-left: 8px;width: 120px;">{{data.Name}}  <span *ngIf="data.Validate=='True'" class="text-danger">*</span></td>
    
                                <td>
                                    <select  *ngIf="data.Type=='Select'" class="select2-option form-control"  aria-hidden="true"
                                     required="data.Validate=='True'" [(ngModel)]="appservice.header_Row[data.Field]"
                                        name="{{data.Field}}" #{{data.Field}}="ngModel">
                                       <option *ngFor="let row of appservice.get_ref(data.Options)" value={{row.label}}>
                                           {{row[data.Store_Value]}}
                                       </option>
                                     </select>
    
                                    
                                     <textarea *ngIf="data.Type=='TextArea'" class="form-control"  rows="4"  [readonly]="data.IsEdit=='False'"  required="data.Validate=='True'" [(ngModel)]="appservice.header_Row[data.Field]"
                                     name="{{data.Field}}" #{{data.Field}}="ngModel"   ></textarea>
                                     
                                    
                                    <input *ngIf="!(data.Type=='Select' || data.Type=='TextArea')" class="form-control"   [readonly]="data.IsEdit=='False'"  required="data.Validate=='True'" type="{{data.Type}}" [(ngModel)]="appservice.header_Row[data.Field]"
                                        name="{{data.Field}}" #{{data.Field}}="ngModel"   >
                                        
                                    
                                        <div *ngIf='addForm.submitted && (data.Validate=="True") && (appservice.header_Row[data.Field]=="")' class="invalid-feedback d-block">
                                            <div class="error"> {{data.Name}} Should not entry</div>
                                        </div>  
                                    </td>
                            </tr>
    
                        </table>     
    
            </div>
    
    
    <div class="col-md-3 nopad"></div>
    


    <div class="col-md-4 nopad">
            <table *ngIf="appservice.header_Row.Sub_Total>0"  class="sum_row">
                    <tr *ngIf="appservice.header_Row.Sub_Total!=appservice.header_Row.Net_Amt"  >
                            <td class="left">Sub Total</td>
                            <td></td>
                            <td class="right">{{appservice.header_Row.Sub_Total}}</td>
                    </tr>

                    <tr *ngIf="appservice.header_Row.Disc_Amt>0">
                            <td class="left">Discount</td>
                            <td>{{appservice.header_Row.Disc_Per}}</td>
                            <td class="right">{{appservice.header_Row.Disc_Amt}}</td>
                    </tr>

                    <tr  *ngIf="appservice.header_Row.Sub_Total!=appservice.header_Row.Taxable_Amount" >
                            <td class="left">Taxable</td>
                            <td></td>
                            <td class="right">{{appservice.header_Row.Taxable_Amount}}</td>
                    </tr>


                    <tr  *ngIf="appservice.header_Row.IGST_Amt>0">
                            <td class="left">IGST</td>
                            <td>{{appservice.header_Row.IGST_Per}}</td>
                            <td class="right">{{appservice.header_Row.IGST_Amt}}</td>
                    </tr>

                    
                    <tr   *ngIf="appservice.header_Row.SGST_Amt>0">
                            <td class="left">SGST</td>
                            <td>{{appservice.header_Row.SGST_Per}}</td>
                            <td class="right">{{appservice.header_Row.SGST_Amt}}</td>
                    </tr>

                    
                    <tr   *ngIf="appservice.header_Row.CGST_Amt>0">
                            <td class="left">CGST</td>
                            <td>{{appservice.header_Row.CGST_Per}}</td>
                            <td class="right">{{appservice.header_Row.CGST_Amt}}</td>
                    </tr>

                    
                   
                    <tr   *ngIf="appservice.TCS_Enable==true">
                            <td class="left">TCS</td>

                            <td><input  style="max-width: 40px;"  (keyup)="Calc_Sum()"  value="0"  [(ngModel)]="appservice.header_Row.TCS_Per" type="number" class="sum_text"/></td>
                            <td class="right">{{appservice.header_Row.TCS_Amt}}</td>

                    </tr>
                    <tr>
                            <td class="left">Adjustment</td>
                            <td></td>
                            <td class="right"><input  (keyup)="Calc_Sum()"  value="0"  [(ngModel)]="appservice.header_Row.Adjustment" type="number" class="sum_text"/></td>

                    </tr>
                    
                    <tr>
                            <td class="left">Net Amount</td>
                            <td></td>
                            <td class="right">{{appservice.header_Row.Net_Amt}}</td>
                    </tr>


            </table>
    </div>


    <div class="col-md-12 nopad" style="text-align: right;">

            <input type="button" class="btn btn-secondary  rounded-pill" (click)="Clear_()" style="margin: 10px;" value="Clear" >

            <input type="button" class="btn btn-secondary  rounded-pill" (click)="appservice.back()" style="margin: 10px;" value="Cancel" >
            <button (click)="Place_Order()" class="btn btn-info  rounded-pill" style="margin: 10px;" 
                    [disabled]="btndisable" > 
                    <span *ngIf="btndisable==true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                    <i   *ngIf="btndisable==false" class='bx bx-paper-plane'></i> Submit
            </button>

            
            
            </div>
</div>
<div class="modal fade basicModalLG" id="Customer_Module1"  style="    z-index: 9999999;" data-backdrop="static" data-keyboard="false"  role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered  modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"> Detail</h5>
                <button type="button" class="close"  (click)="Close_Customer_Module1()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="padding-top: 0px;
            padding-bottom: 0px;min-height: 500px;">
     
     <label style="    font-weight: 800;">Purchase Details</label>

             <p-table responsiveLayout="scroll"#dt1 class="table table-striped custom-table" [value]="Uni_Code_Purchase"
             selectionMode="single" [globalFilterFields]="appservice.Supplierwise_Track_GF">
             <ng-template pTemplate="header">
               <tr class="fsize">
                    <th>#</th>
                    <th>Supplier Name</th>
               

                  <th>Item Name</th>
                  <th>MRP</th>
                  <th>Qty</th>
                  <th>P Rate</th>
                  <th>Free</th>

                  <th>Disc per</th>
                  <th>Disc Amt</th>
                  <th>GST(%)</th>
                  <th>Amount</th>

                 
               </tr>
             </ng-template>
             <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
               <tr class="fsize" >
                    <td>{{rowIndex+1}}</td>
                    <td style="    font-weight: 600;">{{rowData.Supplier_Name}}<br>
                            <span class="mhide text-muted">{{rowData.Bill_No}}</span>&nbsp;/&nbsp;
                            <span class="mhide text-muted">{{rowData.Bill_Date}}</span>

                    </td>
                 

                    <td>{{rowData.Item_Name}}</td>
                    <td>{{rowData.MRP}}</td>
                    <td>{{rowData.Qty}}</td>
                    <td style="text-align:right ;">{{rowData.Unit_Price}}</td>
                    <td style="text-align:right ;">{{rowData.Free}}</td>

                    <td style="text-align:right ;">{{rowData.Disc_Per}}</td>

                    <td style="text-align:right ;">{{rowData.Disc_Amt}}</td>
                    <td style="text-align:right ;">{{rowData.GST_Per}}</td>
                   
                    <td style="text-align:right ;">{{rowData.Net_Amt}}</td>

                 
                
               </tr>
             
             </ng-template>
            
           </p-table>
          <br>
          <br>
        
         
  
  
          </div>
          <div class="modal-footer ">
            <table style="width: 100%;">
                    <tr *ngIf="Due_Amt>0"> 
                            <td colspan="4">Total  </td>
                           
      
                            <td style=" text-align: right;color: crimson;"> Bill Amount:
                              {{Due_Amt}}</td>
      
                              <td style=" text-align: right; color: crimson;"> Due Amount:
                                {{Bill_Amt}}</td>
        
                            
                          </tr>
            </table>              
          </div>
        </div>
    </div>
  </div>
