import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-generate-bills',
  templateUrl: './generate-bills.component.html',
  styleUrls: ['./generate-bills.component.scss']
})
export class GenerateBillsComponent implements OnInit {

  Date="";
  constructor(private toastr: ToastrService,public appservice:AppService) {
   }

  ngOnInit(): void {
  }

  Generate_Bill() {
    this.appservice.isload=true;
    this.appservice.get("Api/Transaction/Generate_Bill?Month="+this.appservice.S_From+"&User="+this.appservice.CREATED_BY).subscribe((res: any) => {
      this.appservice.isload=false;
      this.toastr.success("Generated Success");
    });
  }
}
