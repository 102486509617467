import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-sales-vs-rm',
  templateUrl: './sales-vs-rm.component.html',
  styleUrls: ['./sales-vs-rm.component.scss']
})
export class SalesVsRMComponent implements OnInit {

  constructor(public appservice:AppService) { 

    this.appservice.get_Sales_vs_RM();
  }

  ngOnInit(): void {
  }

}
