
<div class="row">
  <div class="col-md-12 nopad mobile_hide">

    <div class="tabs-header border-bottom">
      <ul>
        <li *ngFor="let data of appservice.Analysis_Menu"
          [className]="appservice.URL_Location==data.Link ? 'active' : ''" class="">
          <a (click)="appservice.load_page(data.Link)">{{data.Menu}}</a></li>

      </ul>
    </div>
    <div class="right-corner">
      <div class="dropdown" style="display: inline-flex;">




        <button class="dropdown-toggle" type="button" data-toggle="dropdown" title="more" aria-haspopup="true"
          aria-expanded="false">

          <i class='bx bx-filter'></i>
        </button>
        <div class="dropdown-menu" style="width: 325px;">
          <div style="background-color: teal; color: white; width: 100%; ">
            &nbsp;&nbsp;Search</div>
          <table>


            <tr>
              <td style="width: 80px;">From </td>
              <td>
                <input type="date" class="area form-control form-control-sm" [(ngModel)]="appservice.S_From"
                  name="From_" #From_="ngModel">
              </td>
            </tr>

            <tr>
              <td style="width: 80px;">To </td>
              <td>
                <input type="date" class="area form-control form-control-sm" [(ngModel)]="appservice.S_To" name="To_"
                  #To_="ngModel">
              </td>
            </tr>
            <tr>
              <td> </td>
              <td><button type="button" (click)="appservice.get_Sales_Range()"
                  class="btn btn-info btn-sm">Search</button></td>
            </tr>





          </table>


        </div>
      </div>
    </div>

    
   
   
  <div class="row"style=" margin-right: 0px; padding-top: 5px;">
    <div class="col-md-6">

      <div class="card browser-statistics-box ">

        <div class="card-header">
          <h3>Sales Vs Purchase</h3>
          <br>
        </div>
        <div class="card-body">

          <div id="apex-basic-column-chart"></div>
        </div>


      </div>
      </div>
      <div class="col-md-6">

        <div class="card browser-statistics-box ">
  
          <div class="card-header">
            <h3>Customer Increase</h3>
            <br>
          </div>
          <div class="card-body">
  
            <div id="apex-basic-column-chart"></div>
          </div>
  
  
        </div>
        </div>
      
      
</div>


</div>
</div>