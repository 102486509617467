<div class="row">
    <div class="col-md-12 nopad">
        <div class="card content ">

            <div class=" Head ">



                <div class="card-header  d-flex justify-content-between align-items-center">
                    <h3>Customers</h3>

                    <div class="dropdown">

                        <a (click)="Back()" style="font-size: 20px;font-weight: 900;cursor: pointer;color:black">
                            <b>X</b>
                        </a>


                    </div>
                </div>

            </div>

            <div class="content_body">



                <form #addForm="ngForm" (ngSubmit)="addData(addForm)" (keydown.enter)="$event.preventDefault()"
                    novalidate>



                    <div class="row">

                        <div class="col-sm-6">

                            <div class="form-table table-responsive">
                                <table class="table table-bordered">


                                    <tbody>
                                        <tr class="hidden">
                                            <td>ID <span class="text-danger">*</span></td>
                                            <td><input class="form-control" type="text" [(ngModel)]="add.ID" name="ID"
                                                    #ID="ngModel" required></td>
                                        </tr>



                                        <tr class="hidden">
                                            <td>Code<span class="text-danger">*</span></td>
                                            <td><input class="form-control" type="text" [(ngModel)]="add.Code"
                                                    name="Code" #Code="ngModel"></td>
                                        </tr>



                                        <tr>
                                            <td style="width :25%;min-width: 100px;">Name<span
                                                    class="text-danger">*</span></td>
                                            <td>
                                                <input class="form-control" autofocus type="text" required
                                                    [(ngModel)]="add.Name" name="Name" #Name="ngModel">
                                                <div *ngIf="addForm.submitted && Name.invalid"
                                                    class="invalid-feedback d-block">
                                                    <div class="error"> Name Should not entry</div>
                                                </div>

                                            </td>
                                        </tr>



                                        <tr>
                                            <td>Phone&nbsp;No<span class="text-danger">*</span> </td>
                                            <td> <input class="form-control" required type="text"
                                                    [(ngModel)]="add.Phone_No" name="Phone_No" #Phone_No="ngModel">
                                                <div *ngIf="addForm.submitted && Phone_No.invalid"
                                                    class="invalid-feedback d-block">
                                                    <div class="error"> Phone No Should not entry</div>
                                                </div>
                                            </td>
                                        </tr>



                                        <tr>
                                            <td>Address1 </td>
                                            <td> <input class="form-control" type="text" [(ngModel)]="add.Address1"
                                                    name="Address1" #Address1="ngModel">
                                            </td>
                                        </tr>



                                        <tr>
                                            <td>Address2 </td>
                                            <td><input class="form-control" type="text" [(ngModel)]="add.Address2"
                                                    name="Address2" #Address2="ngModel">
                                            </td>
                                        </tr>



                                        <tr>
                                            <td>Address3</td>
                                            <td><input class="form-control" type="text" [(ngModel)]="add.Address3"
                                                    name="Address3" #Address3="ngModel">
                                            </td>
                                        </tr>



                                        <tr>
                                            <td>City</td>
                                            <td>
                                                <input class="form-control" type="text" [(ngModel)]="add.City"
                                                    name="City" #City="ngModel">

                                            </td>
                                        </tr>


                                        <tr>
                                            <td>State<span class="text-danger">*</span> </td>
                                            <td style="padding: 0px!important;">

                                        <tr>
                                            <td style="width: 30%;"><input class="form-control" type="text"
                                                    [(ngModel)]="add.State_code" name="State_code"
                                                    #State_code="ngModel">
                                            </td>
                                            <td style="width: 70%;">
                                                <input class="form-control" type="text" [(ngModel)]="add.State"
                                                    name="State" #State="ngModel">
                                                <div *ngIf="addForm.submitted && State.invalid"
                                                    class="invalid-feedback d-block">
                                                    <div class="error">State Should not entry</div>
                                                </div>
                                            </td>
                                        </tr>

                                        </td>
                                        </tr>







                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-sm-6">

                            <div class="form-table table-responsive mb-30">
                                <table class="table table-bordered">


                                    <tbody>
                                        <tr>
                                            <td style="width :25%; min-width: 100px;">Email ID </td>
                                            <td> <input class="form-control" type="text" [(ngModel)]="add.Email_ID"
                                                    name="Email_ID" #Email_ID="ngModel">
                                            </td>
                                        </tr>



                                        <tr>
                                            <td><label>GST No </label></td>
                                            <td> <input class="form-control" type="text" [(ngModel)]="add.GST_NO"
                                                    name="GST_NO" #GST_NO="ngModel">
                                            </td>
                                        </tr>







                                    </tbody>
                                </table>

                                <div class="invoice-btn-box text-right " style="padding-bottom:20px">

                                    <button class="btn btn-info rounded-pil" type="submit"> <i
                                            class='bx bx-paper-plane'></i> Save </button> &nbsp;&nbsp;
                                    <button class="btn btn-secondary rounded-pil " type="button"
                                        (click)="Back()">Cancel</button> &nbsp;&nbsp;
                                </div>




                            </div>
                        </div>

                    </div>


                </form>

                <br>

            </div>
        </div>
    </div>