




<div class="row">
  <div class="col-md-12 nopad">
    <div class="card content ">




      <div class=" Head ">
        <div class="card-header  d-flex justify-content-between align-items-center">
          <h3>Orders</h3>

          <div class="dropdown">


            <a class="dropdown-toggle   addbtn" routerLink="/order-entry" data-toggle="tooltip" data-placement="bottom"
          itle="Add New">

              <i class='bx bx-list-plus'></i>
            </a>


            <span style="padding-left: 10px;padding-right: 10px;">
              <button class="dropdown-toggle" type="button" data-toggle="dropdown" title="more" aria-haspopup="true"
                aria-expanded="false">
                <i class='bx bx-filter'></i>
              </button>
              <div class="dropdown-menu" style="width: 225px;">
                <div style="background-color: teal; color: white; width: 100%; line-height: 30px;">&nbsp;&nbsp;Search
                </div>
                <table class="table table-borderless">
                  <tr>
                    <td style="width: 80px;">From </td>
                    <td><input style="height: 30px;font-size: 14px;
                    width: 122px;" type="date" class="form-control" [(ngModel)]="appservice.S_From" name="From"
                        #From="ngModel"></td>
                  </tr>
                  <tr>
                    <td>To </td>
                    <td><input style="height: 30px;font-size: 14px; width: 122px;" type="date" class="form-control"
                        [(ngModel)]="appservice.S_To" name="To" #To="ngModel"></td>
                  </tr>

                  <tr>
                    <td> </td>
                    <td><button type="button" (click)="search()" class="btn btn-info btn-sm">Search</button></td>
                  </tr>

                </table>

              </div>
            </span>


            <span>
              <button class="dropdown-toggle" type="button" data-toggle="dropdown" title="more" aria-haspopup="true"
                aria-expanded="false">
                <i class='bx bx-dots-vertical'></i>
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                  <i class='bx bx-download'></i> Exprot
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                  <i class='bx bx-loader-circle'></i> Refresh
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                  <i class='bx bx-bulb'></i> Page Tips
                </a>

              </div>
            </span>
          </div>
        </div>

      </div>

      <div class="content_body" style="  min-height: 500px; margin-left: -15px;    padding-top: 0px;
        margin-right: -15px;">
        <div style="width: 100%;">

          <div class="card recent-orders-box mb-30"
            style=" box-shadow:none;padding: 5px!important;padding-top: 20px;padding-bottom: 20px;">


            <div class="spin_block" *ngIf="appservice.isload">

              <div class="d-flex justify-content-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>

            </div>
            <div class="table-responsive">
              <table class="table table-hover ">
                <thead>
                  <tr>
                    <th>Order No</th>
                    <th class="mhide" style="width: 80px;">Date</th>
                    <th style="text-align: left;">Customer</th>
                    <th>Amount</th>
                    <th class="mhide">Status</th>
                    <th style="width: 30px; "></th>


                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let data of appservice.order_Rows" style="cursor: pointer;">
                    <td>{{data.Order_No}}
                      <span class="mshow text-muted">{{data.Date | slice:0:6 }}</span>

                    </td>
                    <td class="mhide">{{data.Date }}</td>
                    <td style="text-align: left;">{{data.Customer_Name}}&nbsp;&nbsp;<br class="mshow"><span
                        class="text-muted">{{data.Contact_No}}</span></td>
                    <td>{{data.Net_Amt}}</td>
                    <td class="mhide"><span class="badge badge-primary"> Pending

                      </span>

                    </td>
                    <td>
                      <div class="dropdown">
                        <button class="dropdown-toggle" type="button" data-toggle="dropdown" title="more"
                          aria-haspopup="true" aria-expanded="false">
                          <i class='bx bxs-badge-check' style="color: #43d39e;"></i>
                        </button>
                        <div class="dropdown-menu">
                          <a (click)="veiw_data(data)" class="dropdown-item d-flex align-items-center"
                            href="javascript:void(0)">
                            <i class='bx bx-show'></i> View
                          </a>
                          <a (click)="edit_data(data)" class="dropdown-item d-flex align-items-center"
                            href="javascript:void(0)">
                            <i class='bx bx-edit-alt'></i> Edit
                          </a>
                          <a (click)="delete_data(data)" class="dropdown-item d-flex align-items-center"
                            href="javascript:void(0)">
                            <i class='bx bx-trash'></i> Delete
                          </a>

                        </div>
                      </div>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>

          </div>

        </div>

        <br>
      </div>
    </div>
  </div>
</div>