
<div class="row">
    <div class="col-md-12 nopad">

        <div class="search-bar">
            <table style="width: 100%;">
                    <tr style="width:100%;height: 50px;">
                           <td  class="search-bal-li" >
                        <li (click)="appservice.back()"    class="bx bx-left-arrow-alt" style=" font-size: 30px; line-height: 50px;"></li>
                        </td>
                           <td><input [(ngModel)]="txt_search"
                            name="txt_search" #txt_search1="ngModel" type="text" (keyup)="search_data(txt_search)"  id="item_Search_bar" 
                             class="search-form-control"  placeholder="Search..."></td>
                            
                           <td  (click)="appservice.back()" class="search-bal-li">
                            <li  class="bx bx-save" style=" font-size: 30px; line-height: 50px;"></li>
                            </td>
                       </tr>
                    </table>
                   </div>

        
        </div>
</div>

    <div class="row">
        <div class="col-6 col-sm-4 col-md-3 " style="padding: 1px;" *ngFor="let data of appservice.Item_Master_Rows">
            <div class="Cart-Card">
                <img class="icon-img" style="width: 150px;"
                src="{{appservice.Server_URL}}/image/C{{appservice.Company}}/I{{data.ID}}.png"
                alt="First slide">
                <div class="pro-content">
                <div class="prod-Name">{{data.Item_Name}}</div>
                <div class="desc">{{data.Description}}</div>
                </div>
                <div class="mrp">
                    MRP : <span class="ma">{{data.compareAtPrice}}</span>  <span class="pro">{{((data.compareAtPrice-data.Rate)/data.compareAtPrice)*100 | number:'1.0-0'}}% OFF</span>
                </div>


                <div class="price">
                    <table >
                        <tr>
                            <td> ₹ {{data.Rate}}</td>
                            <td style="text-align: right;"> 
                            <button *ngIf="data.Order_Qty==0" (click)="appservice.add_item(data,appservice.add_one)" class="btn  btn btn-danger btn-sm rounded-pill" >Add</button>
                        
                            <div  *ngIf="data.Order_Qty!=0" class="qty">
                        
                                <li (click)="appservice.add_item(data,appservice.remove_one)"  class="bx bx-minus-circle icons"></li>
                                <span class="text">{{data.Order_Qty}}</span>
                                <li (click)="appservice.add_item(data,appservice.add_one)" class="bx bxs-plus-circle icons"></li>
                        
                            </div>
                          </td>
                        </tr>
                    </table>
                   
                </div>

                

            </div>
        </div>
    </div>


