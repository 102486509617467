import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  isload:boolean=false;
  constructor(public confirmationService:ConfirmationService,public appservice:AppService,public router:Router) {
   appservice.Clear_Cart();
   this.appservice.Order_No="0";
   this.appservice.isEdit=false;
   try
   {
      if(this.appservice.order_Rows.length<=0)
      {
      this.search();
      }
    }catch{}
   }

  
  public Rows = [];
  get_Customer() {
this.isload=true;
    this.appservice.getc("Api/Invoice/get_Order_details").subscribe((res: any) => { 
      this.Rows = JSON.parse(res).record; 
      this.isload=false;
    }); 

    
  }


  veiw_data(data)
  {

    this.isload=true;
    this.appservice.get("Api/Invoice/get_Order_Entry_details?Order_No="+data.Order_No).subscribe((res: any) => { 
      this.rows = JSON.parse(res).record; 
      try
      {
        this.appservice.Order_No=data.Order_No;
        this.appservice.Clear_Cart();        

      for(var i=0;i<this.rows.length;i++)
      {
        this.datas=this.appservice.Perment_Item_Master_Row.filter(e=>e.ID==this.rows[i]["Item_ID"])[0];
        
        this.appservice.add_item(this.datas,this.rows[i]["Qty"]);
      }
      this.appservice.Selected_Customer=this.appservice.Ledger_Master_Rows.filter(e=> e.ID==data.Ledger_ID)[0];
      
    this.isload=false;
      return this.router.navigate(['/view-order-details']);
    }catch{  this.isload=false;}
    }); 
    

  }

  datas:any={};
  rows=[];
  edit_data(data)
  {
    
    
    this.isload=true;
    this.appservice.get("Api/Invoice/get_Order_Entry_details?Order_No="+data.Order_No).subscribe((res: any) => { 
      this.rows = JSON.parse(res).record; 
      try
      {
        this.appservice.Order_No=data.Order_No;
        this.appservice.Clear_Cart();        
        this.appservice.isEdit=true;
      for(var i=0;i<this.rows.length;i++)
      {
        this.datas=this.appservice.Perment_Item_Master_Row.filter(e=>e.ID==this.rows[i]["Item_ID"])[0];
        
        this.appservice.add_item(this.datas,this.rows[i]["Qty"]);
      }
      this.appservice.Selected_Customer=this.appservice.Ledger_Master_Rows.filter(e=> e.ID==data.Ledger_ID)[0];
      
    this.isload=false;
      return this.router.navigate(['/order-entry']);
    }catch{  this.isload=false;}
    }); 
    
  }

  
  delete_data(data)
  {

    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete press Yes?',
      accept: () => {
        this.Delete(data.Order_No) 
      }
  });
  }


  
  Delete(item) {
    this.isload=true;
    this.appservice.get("Api/Invoice/delete_Customer_Order?Order_No=" + item).subscribe((res: any) => {
    this.search();
    });
  }


  search()
  {

    
    this.appservice.get_orders();

  }

  ngOnInit(): void {

    
  
  }

}
