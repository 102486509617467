import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-category-image',
  templateUrl: './category-image.component.html',
  styleUrls: ['./category-image.component.scss']
})
export class CategoryImageComponent implements OnInit {

  date="Test"
  constructor(public datePipe:DatePipe,public appservice :AppService,public router:Router) { 

    this.date = this.datePipe.transform(new Date(),'ddMMyyyyhhmmss');
    
  }

  ngOnInit(): void {
  }

  edit_category(data)
  {
    
    this.appservice.isadd="1";
   
    this.appservice.Edit_Row=data;
   
    return this.router.navigate(['/update-category']);

  }
}
