
<div class="row">
    <div class="col-md-12 nopad">
        <div class="card content ">

            <div class=" Head ">



                <div class="card-header  d-flex justify-content-between align-items-center">
                    <h3>Profile</h3>

                    <div class="dropdown">

                        <a (click)="appservice.back()" class="x">
                            <b>X</b>
                        </a>


                    </div>
                </div>

            </div>

            <div class="content_body">

                


                <form >

                    <div class="row">




                        <div class="col-sm-5">

                            <div  class="card user-info-box mb-30" style="margin-top: 70px;">
                               
                                <div  class="card-body">
                                    <br>
                                    <div class="col-sm-6 nopad">
                                        
                                        <img class="image"  style="width: 150px;
                                        height: 150px;
                                        border-radius: 50%;
                                        border: #d8dedc;
                                        border-style: solid;
                                        border-width: thick;   
                                        box-shadow: 1px 5px 13px 0 rgb(140 114 169);
                                        margin-top: -110px;"
                                         src="assets/img/02.jpg"
                                         (error)="img.src = '../../assets/img/empty.png'" #img
                                         />
                                       
                                         <br>
                                    </div>

                                    <ul  class="list-unstyled mb-0">
                                        

                                        <li  style="margin-top: 20px;
                                        font-weight: 800;
                                        color: #4e2879;" class="d-flex"><i  class="bx bx-briefcase mr-2"></i><span
                                                 class="d-inline-block">{{data.UM_Full_Name}}  <a 
                                                    class="d-inline-block" href="javascript:void(0)"></a></span></li>
                                        <li  class="d-flex"><i  class="bx bxs-graduation mr-2"></i><span
                                                 class="d-inline-block">{{data.UM_Full_Name}} <a
                                                     class="d-inline-block" href="javascript:void(0)"></a></span>
                                        </li>

                                        <li  class="d-flex"><i  class="bx bxs-graduation mr-2"></i><span
                                            class="d-inline-block">{{data.UM_Full_Name}} <a
                                                class="d-inline-block" href="javascript:void(0)"></a></span>
                                   </li>
                                      
                                        <li  class="d-flex"><i  class="bx bx-home-circle mr-2"></i><span
                                                 class="d-inline-block"> <a  class="d-inline-block"
                                                    href="javascript:void(0)">{{data.CM_Address1}} {{data.CM_Address2}} {{data.CM_Address3}}</a></span></li>
                                        
                                        <li  class="d-flex"><i  class="bx bx-phone mr-2"></i><span
                                                 class="d-inline-block">Phone : <a  class="d-inline-block"
                                                    href="javascript:void(0)">{{data.CM_Phone_Res}}</a></span></li>
                                        <li  class="d-flex"><i  class="bx bx-envelope mr-2"></i><span
                                                 class="d-inline-block">Email <a  class="d-inline-block"
                                                    href="javascript:void(0)">{{data.CM_Email_ID}}</a></span></li>
                                       
                                    </ul>


                                  
                                     
                                </div>
                            </div>
                        </div>



                     








                        <div class="col-sm-6">
                           
                            <div  class="user-profile-images">
           
               
                                <div  class="profile-image">
                                    <img  style="border-radius: 50%;
                                    width: 170px;
                                    height: 170px;
                                    border: 5px solid #fff;
                                    box-shadow: 0 0 20px 0 rgb(0 0 0 / 41%); "alt="image" src="assets/img/02.jpg">
                                    <div  class="upload-profile-photo">
                                        <a  href="javascript:void(0)">
                                            <i  class="bx bx-camera">
            
                                            </i><span>Update</span></a></div></div>
                                            <div class="user-profile-text">
                                                <h3 >  {{data.UM_Full_Name}}</h3>
                                                <span  class="d-block">Developer</span></div></div> 
            
                      
                      </div>

                            </div>

                    
                </form>
            </div>
        </div>
    </div>
</div>