import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sales-dashboard-two',
  templateUrl: './sales-dashboard-two.component.html',
  styleUrls: ['./sales-dashboard-two.component.scss']
})
export class SalesDashboardTwoComponent implements OnInit {

  constructor(public appservice:AppService,private router: Router,) {
    
  }
  addPurchase() {
    this.appservice.isadd = "0";
    return this.router.navigate(['Purchase/purchase-entry']);
  }
  addPurchase_detais() {
    return this.router.navigate(['Purchase/purchase-details']);
  }
  load_sales()
  {
    this.router.navigate(['/sales/sales-entry']);
  }
  load_sales_details()
  {
    this.router.navigate(['sales-details']);
  }
  add_receipt()
     {
      this.router.navigate(['/transaction/add-receipt-details']);
     }
     add_receipt_details()
     {
      this.router.navigate(['/transaction/receipt-details']);
     }
     
     add_payment()
     {
      this.router.navigate(['/transaction/add-payment']);
     }
     add_payment_details()
     {
      this.router.navigate(['/transaction/payment-details']);
     }
     add_Expense()
     {
       this.appservice.isadd="0";
       this.router.navigate(['/transaction/add-expense']);
     }
     add_Expense_details()
     {
       this.router.navigate(['/transaction/expense-details']);
     }
     add_Receivable_out()
     {
      this.router.navigate(['/report/customerwise-out']);
     }
     add_payable_out()
     {
      this.router.navigate(['/report/supplierwise-out']);
     }
  
  
     add_Reports()
     {
      this.router.navigate(['/report']);
     }
     add_Item() {
      this.appservice.isadd = "0";
      this.appservice.isEdit=false;
      return this.router.navigate(['/add-items1']);
    }
    add_Item_Detais()
     {
      this.router.navigate(['/master/item-master1']);
     }
    add_Ledger() {
      this.appservice.isadd = "0";
      this.appservice.Edit_Row ={};
      this.appservice.isEdit=false;
      return this.router.navigate(['master/ledger-entry']);
    }
    add_Ledger_Detais()
     {
      this.router.navigate(['/master/ledger-details']);
     }
    add_Day()
    {
     this.router.navigate(['/report/day-book']);
    }
    add_Vadi_Details()
    {
     this.router.navigate(['/transaction/vadi-details']);
    }
    add_Vadi_Entry()
    {
     this.router.navigate(['/transaction/vadi-entry']);
    }
  ngOnInit() {
      
      
  }
  }
  