


<div class="head">
    <span class="text">Category list</span>
</div>



<div class="col-md-12 nopad">
    <div class="row">
  
        <div class="col-4 col-sm-3 col-md-2 "  style="padding: 2px;margin: 2px;"

  
            *ngFor="let data of appservice.get_ref('Item_Category')">
            <div class="icon-box" tabindex="1">
                <div (click)="edit_category(data)"  class="icon-box-inner" style="text-align: center;">
                    <img class="icon-img" style="width: 150px;"
                        src="{{appservice.Server_URL}}/image/C{{appservice.Company}}/Category/C{{data.RGV_iID}}.png?{{date}}"
                        alt="First slide">

                    <div class="icon-box-name"> {{data.RGV_vDesciption}}  <li class="bx bx-pencil"></li></div>
                </div>
            </div>
        </div>
    </div>

</div>

