import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-b2-c-report',
  templateUrl: './b2-c-report.component.html',
  styleUrls: ['./b2-c-report.component.scss']
})
export class B2CReportComponent implements OnInit {

  isload: boolean = false;
  constructor(public appservice: AppService, public confirmationService: ConfirmationService, public router: Router) {

     
      this.appservice.get_Purchase_Details();
    
  }


  

 
  public Rows = [];
  

  veiw_data(data) {

    this.isload = true;
    this.appservice.get("Api/Invoice/get_Order_Entry_details?Order_No=" + data.Order_No).subscribe((res: any) => {
      this.rows = JSON.parse(res).record;
      try {
        this.appservice.Order_No = data.Order_No;
        this.appservice.Clear_Cart();

        for (var i = 0; i < this.rows.length; i++) {
          this.datas = this.appservice.Perment_Item_Master_Row.filter(e => e.ID == this.rows[i]["Item_ID"])[0];

          this.appservice.add_item(this.datas, this.rows[i]["Qty"]);
        }
        this.appservice.Selected_Customer = this.appservice.Ledger_Master_Rows.filter(e => e.ID == data.Ledger_ID)[0];

        this.isload = false;
        return this.router.navigate(['/view-order-details']);
      } catch{ this.isload = false; }
    });


  }

  datas: any = {};
  rows = [];
 
  export_excel(data)
  {
 
   this.appservice.Excel_Data.Header=this.appservice.B2C_Gst_Export;
   this.appservice.Excel_Data.items=this.appservice.Purchase_Row;
   this.appservice.Excel_Data.Report_Name="B2C GST"
   this.appservice.export_excel();
 
  }


  export_pdf(data)
 {

  this.appservice.Excel_Data.Header=this.appservice.B2C_Gst_Export;
  this.appservice.Excel_Data.Total_Row= "Total Rows : "+this.appservice.length_of(data);
  this.appservice.Excel_Data.Total_Amount="Total Amount : "+this.appservice.sum_of(data,'Net_Amt');
  this.appservice.Excel_Data.items=this.appservice.Purchase_Row
  this.appservice.Excel_Data.Report_Name="B2C GST"
  this.appservice.export_pdf();
  }

  

  ngOnInit(): void {
  }

}
