import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  data :any={
    'UM_Full_Name':''
  }
  constructor(public appservice:AppService) { 
    this.data=JSON.parse(localStorage.getItem('User_Data'));
    this.get_compnay_Details();

  }
  get_compnay_Details()
  {
     this.appservice.getc("Api/Common/Get_Company").subscribe((res:any )=>{
     this.appservice.data=JSON.parse(res).record;
    });
  }
  ngOnInit(): void {
  }

}
