<div class="row">
    <div class="col-md-12 nopad">
  
      <div class="col-md-12 nopad mobile_hide">
       
        <div class="w_79" style="position: fixed;z-index: 99; ">
        
          <div class="tabs-header border-bottom">
            <ul>
              <li> <a (click)="appservice.load_page('/report/pending-orders')">Purchase GST</a></li>
              
            </ul>
          </div>
  
  
          <div class="right-corner">
            <div class="dropdown" style="display: inline-flex;">
  
              
              <a class="dropdown-toggle  serch_icon   mshow"  data-toggle="tooltip" data-placement="bottom"
              title="search" >
             
                <i style="    font-size: 20px;" class='bx bx-search'></i> 
              </a>
  
  
              <button class="dropdown-toggle" type="button" data-toggle="dropdown" title="more" aria-haspopup="true"
                aria-expanded="false">
                <i class='bx bx-filter'></i>
              </button>
              <div class="dropdown-menu" style="width: 325px;">
                <div style="background-color: teal; color: white; width: 100%; ">
                  &nbsp;&nbsp; Search</div>
                <table>
  
                  <tr>
                    <td style="width: 80px;">From </td>
                    <td>
                      <input type="date" class="form-control form-control-sm" [(ngModel)]="appservice.S_From" name="From_"
                        #From_="ngModel">
                    </td>
                  </tr>
  
  
                  <tr>
                    <td style="width: 80px;">To </td>
                    <td>
                      <input type="date" class="area form-control form-control-sm" [(ngModel)]="appservice.S_To"
                        name="To_" #To_="ngModel">
                    </td>
                  </tr>
  
                  <tr>
                    <td style="width: 80px;">Bill Type </td>
                    <td>
                      <select class="area form-control form-control-sm" [(ngModel)]="appservice.S_Bill_Type"
                        name="S_Bill_Type" #S_Bill_Type="ngModel" style="width:100%" aria-hidden="true">
                        <option value="All">
                          All
                        </option>
                        <option value="Tax Invoice">
                          Tax Invoice
                        </option>
                        <option value="Non Tax Invoice">
                          Non Tax Invoice
                        </option>
                      </select>
                    </td>
                  </tr>
  
  
                  
  
  
  
                 
                  <tr>
                    <td> </td>
                    <td><button type="button" (click)="appservice.get_Purchase_Details()"
                        class="btn btn-info btn-sm">Search</button></td>
                  </tr>
                </table>
                <div  class="search_Footer border-top" style="color: green;"> 
                  <div (click)="export_excel(dt)"  class="buttons">
                    <span><li class="bx bx-download"></li></span>
                   <span> Export Excel</span>
                   
                  </div>
  
                 
                  <div (click)="export_pdf(dt)"  class="buttons " style="color: red;">
                    <span><li class="bx bx-download"></li></span>
                   <span> Export PDF</span>
                   
                  </div>
  
  
              </div>
              </div>
            </div>
          </div>
  
  
        </div>
      </div>
  
      <div class="col-md-12 nopad">
        <div class="spin_block" *ngIf="appservice.isload">
          <div class="d-flex justify-content-center">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          }
          
         </div>
        </div>
        <div class="m_2 res_table" style="overflow: auto; max-height:500px">
          <div class="search-bar_Ext mhide  show_bar " style="right: 80px;">
          
            <li  class="bx bx-left-arrow-alt mshow  search_back"></li>
  
                <input  type="text"  (input)="dt.filterGlobal($event.target.value, 'contains')" 
                class="search-form-control text"
                placeholder="Search...">
    
          </div>
  
        
          <p-table responsiveLayout="scroll"#dt  class="table table-striped custom-table fsize" [value]="appservice.Purchase_Row"
            selectionMode="single" [globalFilterFields]="appservice.Purchase_Master_GF">
            <ng-template pTemplate="header">
              <tr  class="fsize">

                <th>#</th>
                <th style="min-width: 100px;">Purchase No</th>

                <th class="mhide"style="min-width: 100px;" >Bill No</th>
                <th class="mhide" >Bill Date </th>
                <th style="white-space: pre">Supplier Name</th>
                <th class="mhide">Area</th>

                <th style="white-space: pre" class="mhide">GST  No</th>
                <th style="text-align: right;">Disc Amt</th>

                <th style="text-align: right;">Taxable</th>
                <th style="text-align: right;">IGST</th>
                <th style="text-align: right;">SGST</th>
                <th style="text-align: right;">CGST</th>
                <th style="text-align: right;">Tax</th>
                <th style="text-align: right;">Sub Total</th>

                <th style="text-align: right;">Amount</th>
               
                
                
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
              <tr   style="white-space: pre" class="fsize">

                <td>{{rowIndex+1}}</td>
                <td style="min-width:85px;white-space: pre" >{{rowData.Purchase_No}}

                <td  style="white-space: pre" >{{rowData.Bill_No}}
  
                  <span  style="white-space: pre" class="mshow  text-muted"> {{rowData.Bill_Date}}</span>
                </td>
                <td   style="white-space: pre" class="mhide">{{rowData.Bill_Date}}</td>
                <td  style="white-space: pre" >{{rowData.Supplier_Name}}
                  <span class="mshow text-muted"> {{rowData.GST_No}}</span>
                </td>
                <td  style="white-space: pre"class="mhide">{{rowData.Area}}</td>

                <td  style="white-space: pre" class="mhide">{{rowData.GST_No}}</td>
                <td style="text-align: right;white-space: pre">{{rowData.Desc_Amt}}

                <td style="text-align: right;">{{rowData.Taxable_Amount}}
                <td style="text-align: right;">{{rowData.IGST_Amt}}
                <td style="text-align: right;">{{rowData.SGST_Amt}}
                <td style="text-align: right;">{{rowData.CGST_Amt}}
                <td style="text-align: right;">{{rowData.Tax_Amt}} 
                  <td style="text-align: right;">{{rowData.Sub_Total}} 

                <td style="text-align: right;">{{rowData.Net_Amt}}
                    
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      <div class="col-md-12 nopad">
        <div class="w_79" style="  bottom: 0;
                font-weight: 700;
                color: #333333;
                background-color: #f4f4f4;
                padding: 0.571em 0.857em;
                position: fixed;
                bottom: 0;
            
                
                border: 1px solid #c8c8c8;">
          <table style="width: 100%;">
            <tr>
              <td style="color: darkslategray;">Total Items : {{appservice.length_of(dt)}} </td>
              
                <td style="text-align: right;     color: crimson;"> Taxable :
                  {{appservice.sum_of(dt,'Taxable_Amount')| number:'1.0-0'}}</td>
                  <td style="text-align: right;     color: crimson;"> IGST :
                    {{appservice.sum_of(dt,'IGST_Amt')| number:'1.0-0'}}</td>
                    <td style="text-align: right;     color: crimson;"> SGST :
                      {{appservice.sum_of(dt,'SGST_Amt')| number:'1.0-0'}}</td>
              <td style="text-align: right;     color: crimson;"> CGST :
                {{appservice.sum_of(dt,'CGST_Amt')| number:'1.0-0'}}</td>
                <td style="text-align: right;     color: crimson;"> Tax :
                  {{appservice.sum_of(dt,'Tax_Amt')| number:'1.0-0'}}</td>
                  <td style="text-align: right;     color: crimson;"> Amount :
                    {{appservice.sum_of(dt,'Net_Amt')| number:'1.0-0'}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  
  
  
  
  
