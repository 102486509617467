<div class="row">
    <div class="col-md-12 nopad">
  
      <div class="col-md-12 nopad mobile_hide">
       
        <div class="w_79" style="position: fixed;z-index: 99; ">
        
          <div class="tabs-header border-bottom">
            <ul>
                <li> <a style="display: inline-flex;">Deleted Order Details</a></li>

                    
            </ul>
          </div>
  
          <div class="right-corner">
            <div class="dropdown"style="display: inline-flex;">
              <a class="dropdown-toggle  serch_icon   mshow"  data-toggle="tooltip" 
              data-placement="bottom"
            title="search" >
           
              <i style="    font-size: 20px;" class='bx bx-search'></i> 
            </a>

             
  
              <button class="dropdown-toggle" type="button" data-toggle="dropdown" 
              title="more" aria-haspopup="true"
                aria-expanded="false">
                <i class='bx bx-filter'></i>
              </button>
              <div class="dropdown-menu" style="width: 325px;">
                <div style="background-color: teal; color: white; width: 100%; ">
                  &nbsp;&nbsp;Search</div>
                  <table>
  
                    <tr>
                      <td style="width: 80px;">From </td>
                      <td>
                        <input type="date" class="form-control form-control-sm" [(ngModel)]="appservice.S_From" name="From_"
                          #From_="ngModel">
                      </td>
                    </tr>
    
    
                    <tr>
                      <td style="width: 80px;">To </td>
                      <td>
                        <input type="date" class="area form-control form-control-sm" [(ngModel)]="appservice.S_To"
                          name="To_" #To_="ngModel">
                      </td>
                    </tr>
    
                    <tr>
                      <td> </td>
                      <td><button type="button" (click)="appservice.Delete_Order_Details()"
                          class="btn btn-info btn-sm">Search</button></td>
                    </tr>
                  </table>
               
              </div>
            </div>
          </div>
  
        </div>
      </div>
  
      <div class="col-md-12 nopad">
        <div class="spin_block" *ngIf="appservice.isload">
          <div class="d-flex justify-content-center">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div class="m_2 res_table">
          <div class="search-bar_Ext mhide  show_bar " style="right: 50px;">
        
            <li  class="bx bx-left-arrow-alt mshow  search_back"></li>
  
                <input  type="text"  (input)="dt.filterGlobal($event.target.value, 'contains')" 
                class="search-form-control text"
                placeholder="Search...">
    
          </div>
  
        
          <p-table responsiveLayout="scroll"#dt class="table table-striped custom-table" [value]="appservice.Delete_Order_Details_Row"
            selectionMode="single"[globalFilterFields]="appservice.Deleted_Order_GF">
            <ng-template pTemplate="header">
              <tr>
                <th>#</th>
                <th [pSortableColumn]="'Table_'">Table No</th>
                <th [pSortableColumn]="'Item_Name'">Item Name</th>
                <th [pSortableColumn]="'Qty'">Qty</th>
                <th [pSortableColumn]="'Created_by'" style="text-align: right;">User </th>
                <th [pSortableColumn]="'Created_Date'"style="text-align: right;">Date</th>
                
                
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
              <tr>
                <td>{{rowIndex+1}}</td>
                <td >{{rowData.Table_}}</td>
                <td >{{rowData.Item_Name}}</td>
                <td >{{rowData.Qty}}</td>
                <td>{{rowData.Created_by}}</td>
                <td >{{rowData.Created_Date}}</td>
               
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      <div class="col-md-12 nopad">
        <div class="w_79" style="  bottom: 0;
                font-weight: 700;
                color: #333333;
                background-color: #f4f4f4;
                padding: 0.571em 0.857em;
                position: fixed;
                bottom: 0;
            
                
                border: 1px solid #c8c8c8;">
           
        </div>
      </div>
    </div>
  </div>
  
  
  
  
  