import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
@Component({
  selector: 'app-categorywise-profit',
  templateUrl: './categorywise-profit.component.html',
  styleUrls: ['./categorywise-profit.component.scss']
})
export class CategorywiseProfitComponent implements OnInit {

  
  constructor(public appservice:AppService) {

  
    
  }
  export_excel()
  {
 
   this.appservice.Excel_Data.Header=this.appservice. Categorywise_Profit_Export;
   this.appservice.Excel_Data.items=this.appservice.Categorywise_Profit_Row
   this.appservice.Excel_Data.Report_Name="Categorywise Sales"
   this.appservice.export_excel();
 
  }




  export_pdf(data)
 {

  this.appservice.Excel_Data.Header=this.appservice. Categorywise_Profit_Export;
  this.appservice.Excel_Data.Total_Row= "Total Rows : "+this.appservice.length_of(data);
 this.appservice.Excel_Data.Total_Amount="Total Amount : "+this.appservice.sum_of(data,'Amount');
  this.appservice.Excel_Data.items=this.appservice.Categorywise_Profit_Row
  this.appservice.Excel_Data.Report_Name="Categorywise Sales"
  this.appservice.export_pdf();
  }

 ngOnInit(): void {

 }

}
