import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-billwise-profit',
  templateUrl: './billwise-profit.component.html',
  styleUrls: ['./billwise-profit.component.scss']
})
export class BillwiseProfitComponent implements OnInit {

  constructor(public appservice:AppService) { 

    this.appservice.get_Profit_Details();
  }

  Print_Bill(data)
  {
    this.appservice.get("Api/Hotel/Reprint_takeaway?Bill_No="+data).subscribe((res: any) => {
    
    });
  }


  
  export_excel()
  {
 
   this.appservice.Excel_Data.Header=this.appservice.Billwise_Profit_Export;
   this.appservice.Excel_Data.items=this.appservice.Billwise_Profit_Sum_Row
   this.appservice.Excel_Data.Report_Name="Billwise Profit"
   this.appservice.export_excel();
 
  }


  export_pdf(data)
 {

  this.appservice.Excel_Data.Header=this.appservice.Billwise_Profit_Export;
  this.appservice.Excel_Data.Total_Row= "Total Rows : "+this.appservice.length_of(data);
  this.appservice.Excel_Data.Total_Amount="Total Amount : "+this.appservice.sum_of(data,'Amount');
  this.appservice.Excel_Data.items=this.appservice.Billwise_Profit_Sum_Row
  this.appservice.Excel_Data.Report_Name="Billwise Profit"
  this.appservice.export_pdf();
  }

  ngOnInit(): void {
  }

}
