import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-analysis2',
  templateUrl: './analysis2.component.html',
  styleUrls: ['./analysis2.component.scss']
})
export class Analysis2Component implements OnInit {

  constructor(public appservice: AppService) { }

  ngOnInit(): void {
  }

}
