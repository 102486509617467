<div class="row">

    <div style="display: inline-flex;" class="tabs-header border-bottom">
      
        <ul>
          <li  *ngFor="let data of appservice.Analysis_Menu"  
           [className]="appservice.URL_Location==data.Link ? 'active' : ''"  
           class="" > <a (click)="appservice.load_page(data.Link)">{{data.Menu}}</a></li>
                
        </ul> 
      </div>
</div>