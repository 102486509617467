
  import { Component, OnInit } from '@angular/core';
  import { AppService } from 'src/app/app.service';
  import { Router, ActivatedRoute } from '@angular/router';
  import { HttpClient } from '@angular/common/http';
  import { ToastrService } from 'ngx-toastr';
  import { Location } from '@angular/common';
  import {Subject, Observable} from 'rxjs';
  
  
  @Component({
    selector: 'app-banner-upload',
    templateUrl: './banner-upload.component.html',
    styleUrls: ['./banner-upload.component.scss']
  })
  export class BannerUploadComponent implements OnInit {

    
    public showWebcam = true;
    public allowCameraSwitch = true;
    public multipleWebcamsAvailable = false;
    public deviceId: string;
    public videoOptions: MediaTrackConstraints = {
      // width: {ideal: 1024},
      // height: {ideal: 576}
    };
  
   
    public rows = [];
    public add: any = {};
    public addValidation: boolean = false;
    headers;
    data;
    isadd = "0";
    public Room_Types = [];
    public Room_Floor = [];
    Comp_yes_No:boolean=false;
    constructor(private _location: Location, public appservice: AppService, private toastr: ToastrService, private http: HttpClient, private router: Router, private route: ActivatedRoute) {
      this.isadd = appservice.isadd;
  
  
      this.add.Gender="Male";
      this.add.State_code="33";
      this.add.S_State_code="33";
      this.add.State="Tamil Nadu";
      this.add.S_State="Tamil Nadu";
      this.add.County="India";
      this.add.S_County="india";
  
      if (this.isadd == "0") {
        this.add.ID = "0";
        this.get_Customer_code();
      }
      else {
  
   
      this.add.ID=appservice.Edit_Row.ID;
      this.add.Code=appservice.Edit_Row.Code;
      this.add.Name=appservice.Edit_Row.Ledger_Name;
  
      this.add.Phone_No=appservice.Edit_Row.Ledger_Name;
      this.add.Address1=appservice.Edit_Row.Address1;
      this.add.Address2=appservice.Edit_Row.Address2;
  
      this.add.Address3=appservice.Edit_Row.Address3;
      
      this.add.State=appservice.Edit_Row.State;
      this.add.Phone_No=appservice.Edit_Row.Phone_Number;
  
      this.add.Email_ID=appservice.Edit_Row.EmailID;
      this.add.GST_NO=appservice.Edit_Row.GSTIN;
  
  
    
      }
    }
  
  
    
    get_Customer_code() {
      this.appservice.getc("Api/Master/get_Customer_Code").subscribe(( res: any) => {
        this.add.Code=res;
      });
    }
  
    get_Ledger_Master() { 
      this.appservice.getc("Api/Master/get_Ledger_Master").subscribe((res: any) => { 
        this.appservice.Ledger_Master_Rows = JSON.parse(res).record; 
  
        this.appservice.from_customer=true;
                this.appservice.from_customer_Code=this.add.Code;
                this.Clear();
                this._location.back();
                
      }); 
      } 
  
      
  
    addData(f) {
      
  
      
        f.form.value.image=null;
      
      f.form.value.Company = this.appservice.Company;
      
      if (f.invalid === true)
        this.addValidation = true;
      else {
        this.addValidation = false;
        this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
        this.http.post(this.appservice.Server_URL + 'api/master/insert_Ledger_Master', f.form.value, { headers: this.headers })
          .subscribe(
            (val: string) => {
              if (val == "True") {
                this.toastr.success("Details Salved Success", 'Msg');
                this.get_Ledger_Master();
                
              }
              else {
                this.toastr.error(val, "Error", { timeOut: 3000 });
              }
            },
            response => {
              this.toastr.error('Error ', response, {
                timeOut: 3000
              });
            });
      }
    }
    Back() {
      this._location.back();
    }
    Clear() {
      this.add =
        {
          'ID': '',
          'Code': '',
          'Name': '',
          'Short_Name': '',
          'Address1': '',
          'Address2': '',
          'Address3': '',
          'Address4': '',
          'City': '',
          'State': '',
          'State_code': '',
          'County': '',
          'Pincode': '',
          'Contact_Person': '',
          'Designation': '',
          'Cell_No': '',
          'Phone_No': '',
          'Email_ID': '',
          'GST_NO': '',
          'Acc_type': '',
          'Gender': '',
          'Age': '',
          'Occupation': '',
          'Father_Name': '',
          'Vehicle_No': '',
          'Shipping_Name': '',
          'S_Address1': '',
          'S_Address2': '',
          'S_Address3': '',
          'S_Address4': '',
          'S_City': '',
          'S_State': '',
          'S_State_code': '',
          'S_County': '',
          'S_Pincode': '',
          'Created_Date': '',
          'Created_by': '',
          'Status': '',
        };
    }
  
  
  
    public ngOnInit(): void {
    
    }
  }
  
