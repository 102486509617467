import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
declare let $: any;
@Component({
  selector: 'app-sales-return-details',
  templateUrl: './sales-return-details.component.html',
  styleUrls: ['./sales-return-details.component.scss']
})
export class SalesReturnDetailsComponent implements OnInit {

 
  isload: boolean = false;
  constructor(public confirmationService: ConfirmationService,public appservice:AppService, private router: Router, private route: ActivatedRoute) { 

    this.appservice.Purchase_Master_GF = [...new Set(this.appservice.get_fields_of('Purchase').map(item => item.Field))];
    try
    {
    if (appservice.Sales_Row.length <= 0) {
      this.appservice.get_Sales_Return();
    }
  }catch{
    this.appservice.get_Sales_Return();
  }
    
  
  }
  addReset() {
    this.appservice.isadd = "0";
    return this.router.navigate([this.appservice.Purchase_entry_page]);
  }
Print_Bill(data)
{
  window.open(this.appservice.Server_URL + "report/Sales_Return_Bill?Bill_No=" + data.Bill_No + "&Company=" + this.appservice.Company, "_blank");

}
  edit_data(data) {
  
    this.appservice.header_Row=data;
    this.appservice.from_customer_page == false;
   
    this.appservice.get("Api/Invoice/get_Purchase_Return_Entry_details?Purchase_No=" + data.Purchase_No).subscribe((res: any) => {
      this.appservice.Details_Row = JSON.parse(res).record;
      console.log(this.appservice.Details_Row)
      this.appservice.isEdit=true;
      this.router.navigate(['/sales-return-approved']);
      
    });
  
  }
 
  delete_data(data) {

    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete press Yes?',
      accept: () => {
        this.Delete(data)
      }
    });
  }



  Delete(item) {
    
    this.appservice.get("Api/master/delete_Sales_Return_Details?Bill_No="+item.Bill_No+"&Bill_Date="+item.Bill_Date).subscribe((res: any) => {
      this.appservice.get_Sales_Return();
    });
  }
  
  Close_Customer_Module()
  {
    $('#Customer_Module').modal('hide');
  }

  Open_Customer_Module(data)
  {
    this.appservice.Purchase_Item_Rowdata=data.Purchase_No;

    this.appservice.Purchasewise_Details();

    $('#Customer_Module').modal('show');
  }
  export_excel(data)
  {
    this.appservice.Purchase_Export =  this.appservice.get_grid_fields_of('Purchase');

 
   this.appservice.Excel_Data.Header=this.appservice.Purchase_Export;
   this.appservice.Excel_Data.items=this.appservice.Purchase_Row
   this.appservice.export_excel();
 
  }


  export_pdf(data)
 {

  this.appservice.Purchase_Export =  this.appservice.get_grid_fields_of('Purchase');
  this.appservice.Excel_Data.Total_Row= "Total Rows : "+this.appservice.length_of(data);
  this.appservice.Excel_Data.Total_Amount="Total Amount : "+this.appservice.sum_of(data,'Net_Amt');
  this.appservice.Excel_Data.Header=this.appservice.Purchase_Export;
  this.appservice.Excel_Data.items=this.appservice.Purchase_Row
  this.appservice.export_pdf();
  }

 
 

  ngOnInit(): void {
    this.appservice.isEdit = false;
  }

}
