import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit {

  ngOnInit() { }
  public rows = [];
  public add: any = {};
  public addValidation: boolean = false;
  headers;
  data;
  isadd = "0";
  public Room_Types = [];
  public Room_Floor = [];
  constructor(private _location: Location, public appservice: AppService, private toastr: ToastrService, private http: HttpClient, private router: Router, private route: ActivatedRoute) {
    this.isadd = appservice.isadd;
    if (this.isadd == "0") {
      this.add.ID = "0";
    }
    else {
      this.add = appservice.Edit_Row;
    }

    this.server=appservice.Server_URL+"Api/Master/UploadDocumentImage?ID="+this.add.ID+"&Company="+appservice.Company+"&Name="+this.add.Name+"&Description="+this.add.Description;
   

  }
  get_image()
  {

   var timeStamp = (new Date()).getTime();
   return this.appservice.Server_URL+"image/C"+this.appservice.Company+"/Document/D_"+this.add.ID+".png?data="+timeStamp;
  }
  addData(f) {
    f.form.value.Company = this.appservice.Company;
    f.form.value.File_Type = this.appservice.Document_Type;

    if (f.invalid === true)
      this.addValidation = true;
    else {
      this.addValidation = false;
      this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.post(this.appservice.Server_URL + 'api/master/insert_Document_Upload', f.form.value, { headers: this.headers })
        .subscribe(
          (val: string) => {
            if (val == "True") {
              this.toastr.success("Details Salved Success", 'Msg');
              this.appservice.get_Douments();
              this.Clear();
              this._location.back();

            }
            else {
              this.toastr.error(val, "Error", { timeOut: 3000 });
            }
          },
          response => {
            this.toastr.error('Error ', response, {
              timeOut: 3000
            });
          });
    }
  }


  uploadedFiles: any[] = [];

  server;
  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    this.toastr.success("File Uploaded  Success", 'Msg');

  }


  Back() {
    this._location.back();
  }
  Clear() {
    this.add =
    {
      'ID': '',
      'Ref_Type': '',
      'Ref_ID': '',
      'File_Type': '',
      'File_Name': '',
      'File_Location': '',
      'Name': '',
      'Description': '',
      'Created_by': '',
      'Created_Date': '',
      'Status': '',
    };
  }
}

