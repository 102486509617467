
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';


@Component({
  selector: 'app-document-upload-details',
  templateUrl: './document-upload-details.component.html',
  styleUrls: ['./document-upload-details.component.scss']
})
export class DocumentUploadDetailsComponent implements OnInit {

  ngOnInit() {
  }
  Rows = [];
  cols: any[];
  filter:boolean=false;
  constructor(private toastr: ToastrService, public appservice: AppService, private http: HttpClient, private router: Router, private route: ActivatedRoute) {
    this.appservice.get_Douments();

    this.get_types();
    this.display();

  }


  public Types_ = [];
  get_types() {

    this.Types_ = this.appservice.Reference_Rows.filter(e => e.Ref_ID=="Documents");
    
  }


  addReset() {
    this.appservice.isadd = "0";
    return this.router.navigate(['/document-upload']);
  }
  onEdit(item) {
    this.appservice.isadd = "1";
    this.appservice.Edit_Row = item;
    return this.router.navigate(['/document-upload']);
  }
  onDelete(item) {
    this.appservice.get("Api/master/delete_Document_Upload?ID=" + item).subscribe((res: any) => {
      var index = this.appservice.Document_Upload_Rows.findIndex(function (items, i) {
        return items.ID === item
      });
      if (index > -1) {
       
        this.appservice.Document_Upload_Rows.splice(index, 1);
      }
      this.appservice.Document_Upload_Rows = this.appservice.Document_Upload_Rows;
      this.display();
    });
  }
  display() {
    
    try
    {

    this.Rows = this.appservice.Document_Upload_Rows.filter(e => e.File_Type==this.appservice.Document_Type);
    this.cols = [
     
      { field: 'Name', header: 'Head', width: '' },
      { field: 'Description', header: 'Details', width: '' }
    ];
  }catch{}
}
}

