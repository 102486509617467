import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Location } from '@angular/common';

declare let $: any;

@Component({
  selector: 'app-add-product1',
  templateUrl: './add-product1.component.html',
  styleUrls: ['./add-product1.component.scss']
})
export class AddProduct1Component implements OnInit {


  rate_disp:boolean=false;
  Category_="";
  constructor(private _location: Location, public appservice: AppService, private toastr: ToastrService, private http: HttpClient, private router: Router, private route: ActivatedRoute)  
  { 
    this.appservice.get_stcok_Category();
    if(appservice.U_Rights!=appservice.PRate_disp)
    {
      this.rate_disp=true;
    }
    else
    {
      this.rate_disp=false;
    }

   

    try
    {
      this.Total = (this.appservice.Temp_Order_Row.reduce((sum, current) => sum + ((parseFloat(current.Order_Qty))*(parseFloat(current.Order_Rate))), 0)).toFixed(2);

    }catch{}

    try
    {
      this.Disc_Total = (this.appservice.Temp_Order_Row.reduce((sum, current) => sum + ((parseFloat(current.Order_Qty))*(parseFloat(current.Disc_Amt_))), 0)).toFixed(2);

    }catch{}
   this.get_Bal(appservice.Selected_Customer.ID);
   }


   get_Bal(id)
   { this.appservice.get("Api/Master/get_Customer_Balance?ledger_ID=" + id ).subscribe((res: any) => {
     this.appservice.Customer_Balance=res;
     });}

   Search_text_="";



   Customer_Balance=[];
  public Bill_Amt=0;
  public Due_Amt=0;
  Close_Customer_Module()
  {
    $('#Customer_Module').modal('hide');
  }
  Disc_type="Per";
  public Ledger_Name;
  public Phone_Number;
  public Ledger_ID;
  Open_Customer_Module()
  {
    this.Ledger_Name=this.appservice.Selected_Customer.Ledger_Name;
    this.Phone_Number=this.appservice.Selected_Customer.Phone_Number;
    this.Ledger_ID=this.appservice.Selected_Customer.ID;
      this.Due_Amt=0;
      this.appservice.get("Api/Collection_/get_os_bills?ID="+this.appservice.Selected_Customer.ID).subscribe(( res: any) => {
      this.Customer_Balance = JSON.parse(res).record;
      this.Bill_Amt = (this.Customer_Balance.reduce((sum, current) => sum + parseFloat(current.Bill_Amount), 0)).toFixed(2);
      this.Due_Amt = (this.Customer_Balance.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0)).toFixed(2);
  
   
      });
    

    $('#Customer_Module').modal('show');
  }
  Close_Customer_Module1()
  {
    $('#Customer_Module1').modal('hide');
  }
  

   Search_Item_byName(data) {

    
    /*
    if (data == "All") {
      this.appservice.Stcok_Row = this.appservice.P_Stcok_Row ;

    }
    else {
      this.appservice.Stcok_Row = this.appservice.P_Stcok_Row.filter(e => e.Item_Name.toLowerCase().includes(data.toLowerCase()));
    }


    this.appservice.load_Temp_Order_Row()
*/
if(data.length>=3)
{
this.appservice.Stcok_Row = [];
    this.appservice.loading = true;

    this.appservice.get("Api/Master/get_Item_Stock_byitem_name?Category="+this.appservice.item_Category_+"&Ledger_ID=" + this.appservice.Selected_Customer.ID + "&Item_Name=" + data + "&Check_Stock=" + this.appservice.Check_Stock + "&Order_by= x.Category,Item_Name&Order_No=" + this.appservice.Order_No).subscribe((res: any) => {
      this.appservice.Stcok_Row = JSON.parse(res).record;
      this.appservice.P_Stcok_Row = JSON.parse(res).record;
      
      this.appservice.loading = false;
      this.appservice.load_Temp_Order_Row()
    });
    
  }
  }



  isError() :boolean {
    return this.iserror;
  }


   public iserror: boolean=false;
   get_filter_data()
   {
   
    
    this.appservice.get_Item_Master_Datas(this.appservice.Selected_Customer.ID);

   }

  txt_search="";
  ngOnInit(): void {
  }

 
  public rows = [];
  public add: any = {};
  
   Total="0.00";
   Disc_Total="0.00";

  add_row(data)
  {

    this.iserror=false;
    var index = this.appservice.Temp_Order_Row.findIndex(function (item, i) {
      return (item.Uni_Code == data.Uni_Code && item.Item_ID == data.Item_ID)
    });
    
    
      if (index > -1) {
        this.appservice.Temp_Order_Row.splice(index, 1);
      }
      this.Total = (this.appservice.Temp_Order_Row.reduce((sum, current) => sum + ((parseFloat(current.Order_Qty))*(parseFloat(current.Order_Rate))), 0)).toFixed(2);


    if(data.Order_Rate=="")
    {
      data.error="";
      return;
    }
    
    if(data.Order_Qty=="" && data.Free=="")
    {
      data.error="";
      return;
    }

   
    if(data.Order_Qty=="")
    {
      
      if(data.Free!="")
      {
      data.Order_Qty="0";
      }
    }

       
    if((Number(data.Order_Qty)+Number(data.Free))==0 )
    {
      data.error="";
      return;
    }
    
    try{
    data.Disc_Type=this.Disc_type;
    }catch{}

       
    if(Number(data.Order_Rate)==0 )
    {
      this.iserror=true;
      data.error="";
      return;
    }

    //Number(data.Order_Rate)

   /* if((Number(data.Order_Rate)>Number(data.MRP))  && (Number(data.MRP)>0) )
    {
      this.iserror=true;
      data.error="Rate should not be More than MRP";
      return;
    }*/

    if(Number(data.Order_Rate)<Number(data.Purchse_Price) )
    {
      this.iserror=true;
      data.error="Rate should not be less than Purchse Price";
      return;
    }

    
    if((Number(data.Order_Qty)+Number(data.Free))>Number(data.Stock) && (Number(data.Stock)>0) )
    {this.iserror=true;
      data.error="More than Stock";
      return;
    }
    if(data.Disc_Per=="")
    {
      data.Disc_Per="0";
    }
        
    if((Number(data.Order_Qty)+Number(data.Free))<0 )
    {
      this.iserror=true;
      data.error="Not Valid Qty ";
      return;
    }

    this.iserror=false;
    data.error="";
    if((( data.Disc_per>0 ) && ( data.Disp=='Per')))
      {
        data.Scheme =data.Disc_per+"%";

      }else if(((data.Disc_per>0) && (data.Disp=='Amt')))
      {
        data.Scheme = data.Disc_Amt;
      
       }
    
      if((Number(data.Order_Qty)+Number(data.Free))>0  && Number(data.Order_Rate)>0)
      {
          this.appservice.Temp_Order_Row.push(data);

      }
     

      if(data.Disc_Type=='Per')
      {
        data.Disc_Per_=data.Discount;
        data.Disc_Amt_=(Number(data.Order_Rate)/100)*data.Discount;
      }
      else{
        data.Disc_Amt_=data.Discount;
        data.Disc_Per_=((Number(data.Discount)/Number(data.Order_Rate))*100).toFixed(2)
      }
      
      
      this.Disc_Total = (this.appservice.Temp_Order_Row.reduce((sum, current) => sum + ((parseFloat(current.Order_Qty))*(parseFloat(current.Disc_Amt_))), 0)).toFixed(2);
      
      this.Total = (this.appservice.Temp_Order_Row.reduce((sum, current) => sum + ((parseFloat(current.Order_Qty))*(parseFloat(current.Order_Rate))), 0)).toFixed(2);


  }

  public addValidation: boolean = false;
   headers;
   data;
   isadd = "0";
   isload:boolean=false;

   

}
