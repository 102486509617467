import { Component, OnInit } from '@angular/core';
import ApexCharts from 'apexcharts';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
export class AnalysisComponent implements OnInit {

  constructor(public appservice: AppService) { 

    this.categorywise_Sales();
    this.Customer_Increase();

       
   }
 
  Row_=[];
   R_Day=[];
   R_Sales=[];
   R_Expens=[];
 
   
   categorywise_Sales() {
     var x=0;
    
     this.appservice.getc("Api/Reports/get_Sales_Analysis").subscribe((res: any) => {
       this.Row_ = JSON.parse(res).record;
       var l=0;
       for(var i=this.Row_.length-1;i>=0;i--)
       {
        this.R_Day[l]=this.Row_[i]["Month_"];
        this.R_Sales[l]=parseInt( this.Row_[i]["Sales_"]);
        this.R_Expens[l]=parseInt( this.Row_[i]["Purchase"]);
        x+=parseInt( this.Row_[i]["Sales_"]);
        l++;
       }
 
       const options = {
         chart: {
             height: 360,
             type: 'bar',
         },
         plotOptions: {
             bar: {
                 horizontal: false,
                 columnWidth: '55%',
                 endingShape: 'rounded'	
             },
         },
         dataLabels: {
             enabled: false
         },
         stroke: {
             show: true,
             width: 2,
             colors: ['transparent']
         },
         series: [{
             name: 'Sales',
             data: this.R_Sales
         }, {
             name: 'Purchase',
             data: this.R_Expens
         } ],
         xaxis: {
             categories: this.R_Day,
         },
         yaxis: {
             title: {
                 text: '₹ (Amount)'
             }
         },
         fill: {
             opacity: 1
 
         },
         tooltip: {
             y: {
                 formatter: function (val) {
                     return "₹ " + val + " Amount"
                 }
             }
         },
         legend: {
             offsetY: -10,
         }
     }
     const chart = new ApexCharts(
         document.querySelector("#apex-basic-column-chart"),
         options
     );
     chart.render();
 
     });
   }
   Customer_Row=[];
   R_Month=[];
   R_Customer=[];
   R_Purchase=[];

   Customer_Increase() {
    var x=0;
   
    this.appservice.getc("Api/Reports/get_Customer_Analysis").subscribe((res: any) => {
      this.Customer_Row = JSON.parse(res).record;
      var l=0;
      for(var i=this.Customer_Row.length-1;i>=0;i--)
      {
       this.R_Month[l]=this.Customer_Row[i]["Month_"];
       this.R_Customer[l]=parseInt( this.Customer_Row[i]["Customer"]);
       this.R_Purchase[l]=parseInt( this.Customer_Row[i]["Purchase"]);

       x+=parseInt( this.Row_[i]["Customer"]);
       l++;
      }

      const options = {
        chart: {
            height: 360,
            type: 'bar',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'	
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        series: [{
            name: 'Customer',
            data: this.R_Customer
        },
        {
          name: 'Purchase',
          data: this.R_Purchase
      } ], 
        
        xaxis: {
            categories: this.R_Month,
        },
        yaxis: {
          title: {
              text: '₹ (Amount)'
          }
      },
      fill: {
          opacity: 1

      },
      tooltip: {
          y: {
              formatter: function (val) {
                  return "₹ " + val + " Amount"
              }
          }
      },
        legend: {
            offsetY: -10,
        }
    }
    const chart = new ApexCharts(
        document.querySelector("#apex-basic-column-chart"),
        options
    );
    chart.render();

    });
  }
  
   
   ngOnInit() {
     
 }
 
 }
 