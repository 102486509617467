<div class="row">
    <div class="col-sm-12 nopad ">
      <div class="card content ">
  
        <div class=" Head ">
          <div class="card-header  d-flex justify-content-between align-items-center  ">
            <h3>Reference Group Entry</h3>
  
            <div class="dropdown">
  
              <a (click)="Back()" style="font-size: 20px;font-weight: 900;cursor: pointer;color:black">
                <b>X</b>
              </a>
  
  
            </div>
          </div>
  
        </div>
  
        <div class="content_body">
   
            <form #addForm="ngForm" (ngSubmit)="addData(addForm)" novalidate>
  
  
  
  
              <div class="row">
  
                <div class="col-sm-6">
  
                  <div class="form-table table-responsive">
                    <table class="table table-bordered">
  
  
                      <tbody>
                        <tr class="hidden">
                          <td>ID <span class="text-danger">*</span></td>
                          <td><input class="form-control" type="text" [(ngModel)]="add.ID" name="ID" #ID="ngModel"
                              required></td>
                        </tr>
                        <tr>
                            <td>ID</td>
                            <td>
    
                              <input class="form-control afocus" required type="text" [(ngModel)]="add.RG_iID"
                                name="RG_iID" #RG_iID="ngModel">
                              
                            </td>
                          </tr>
  
                        <tr>
                          <td>RG_vCode<span class="text-danger">*</span></td>
                          <td>
  
                            <input class="form-control afocus" required type="text" [(ngModel)]="add.RG_vCode"
                              name="RG_vCode" #RG_vCode="ngModel">
                            <div *ngIf="addForm.submitted && RG_vCode.invalid" class="invalid-feedback d-block">
                              <div class="error">RG_vCode Should not entry</div>
                            </div>
                          </td>
                        </tr>
  
  
                      
  
  
  
                        <tr>
                          <td>Description </td>
                          <td>
                            <input class="form-control"  type="text" [(ngModel)]="add.RG_vDescription"
                              name="RG_vDescription" #RG_vDescription="ngModel">
                           
                          </td>
                        </tr>
                      
                     
  
  
  
  
                      </tbody>
                    </table>
  
                 
                    <div class="invoice-btn-box text-right " style="padding-bottom:20px">
  
                        <button class="btn btn-info rounded-pil" [disabled]="btndisable" type="submit">


                            <span *ngIf="btndisable==true" class="spinner-border spinner-border-sm"
                                role="status" aria-hidden="true"></span>
                            <i *ngIf="btndisable==false" class='bx bx-paper-plane'></i>
                            Save </button> &nbsp;&nbsp;
                        <button class="btn btn-secondary rounded-pil " type="button"
                            (click)="appservice.back()">Cancel</button> &nbsp;&nbsp;
                    </div>
                  </div>
                </div>
              </div>
  
  
  
            </form>
         
        </div>
      </div>
    </div>
  </div>