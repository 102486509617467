import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-userwise-sales',
  templateUrl: './userwise-sales.component.html',
  styleUrls: ['./userwise-sales.component.scss']
})
export class UserwiseSalesComponent implements OnInit {

  constructor(public appservice:AppService) {

  
    
  }
  export_excel()
  {
 
   this.appservice.Excel_Data.Header=this.appservice. Userwise_Export;
   this.appservice.Excel_Data.items=this.appservice.Userwise_Row
   this.appservice.Excel_Data.Report_Name="Userwise Sales"
   this.appservice.export_excel();
 
  }


 


  export_pdf(data)
 {

  this.appservice.Excel_Data.Header=this.appservice. Userwise_Export;
  this.appservice.Excel_Data.Total_Row= "Total Rows : "+this.appservice.length_of(data);
 this.appservice.Excel_Data.Total_Amount="Total Amount : "+this.appservice.sum_of(data,'Amount');
  this.appservice.Excel_Data.items=this.appservice.Userwise_Row
  this.appservice.Excel_Data.Report_Name="Userwise Sales"
  this.appservice.export_pdf();
  }

 ngOnInit(): void {

 }

}
