<div class="row">
    <div class="col-md-12 nopad">
        <div class="card content ">

            <div class=" Head ">
                <div class="card-header  d-flex justify-content-between align-items-center">
                    <h3>Order Entry</h3>

                    
                  

                    <a (click)="appservice.back()" class="x">
                        <b>X</b>
                    </a>


                    
                </div>

            </div>

            <div class="content_body">


                <div *ngIf="(appservice.Selected_Customer | json) == '{}'" >
                    <div class="d-table" style="width:100%;">
                        <div class="d-table-cell">
                            <div class="maintenance-content" style="box-shadow:none">
                              
                                <a style="height: 100px; width: 100px;" class="logo">
                                    <img src="assets/img/Orders.png" alt="image">
                                </a>
                               
                                <p>Order entry allows you to record information about your customers and the items they have ordered</p>
                
                                <a   (click)="appservice.load_page1('/search_customer')"  style="background-color: #984326;" class="default-btn search_btn">Select Customer</a>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="invoice-middle mb-30" *ngIf="(appservice.Selected_Customer | json) != '{}'">
                    <div class="row">
                        <div class="col-6 col-md-9 ">
                            <div class="text"  style="color: #6084a4;
                            padding-left: 5px;">
                               
                                <div >
                                <h4 class="mb-2" style="margin-left: -10px;">To:-</h4>
                                <span class="d-block mb-1">{{appservice.Selected_Customer.Ledger_Name}}</span>
                                <span class="d-block mb-1">{{appservice.Selected_Customer.Address1}}</span>
                                <span class="d-block mb-1">{{appservice.Selected_Customer.Address2}} </span>
                                <span class="d-block mb-1">{{appservice.Selected_Customer.Address3}}</span>
                                <span class="d-block mb-1">{{appservice.Selected_Customer.Address4}}</span>
                                <span class="d-block mb-1">{{appservice.Selected_Customer.City}} </span>
                                <span class="d-block mb-1">{{appservice.Selected_Customer.State}} </span>
                                <span class="d-block mb-1">{{appservice.Selected_Customer.Pincode}}</span>
        
        
                            </div>
                            </div>
                        </div>
            
                     
            
                        <div class=" col-6 col-md-3  nopad" *ngIf="(appservice.Selected_Customer | json) != '{}'">
                            <div class="text text-right">
                                <table style="width: 98%;">
                                    <tr>
                                        <td>Order No</td>
                                        <td>:</td>
                                        
                                        <td style="text-align: right; font-weight: 700;">{{appservice.Order_No}}</td>
                                    </tr>
                                    <tr>
                                        <td>Date</td>
                                        
                                        <td>:</td>
                                        <td style="text-align: right;font-weight: 700;">{{appservice.Today_Date}}</td>
                                    </tr>
                                </table>
                              
                                
                            </div>
                        </div>
                    </div>
                </div>


                
                <div *ngIf="(appservice.Selected_Customer | json) != '{}' && appservice.cart_item.length==0" >
                    <div class="d-table" style="width:100%;">
                        <div class="d-table-cell">
                            <div class="maintenance-content" style="box-shadow:none">
                             
                                <a  routerLink="/add-product1" class="default-btn isearch_btn" style="background-color:#2276d0">Select Items</a>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row"   *ngIf="appservice.cart_item.length>0">

                    <div class="col-md-8 mpx" >
                        <div class="cart-view">
                            <div class="head_text">Items Details
                            
                            <a  style="right: 10px;position: absolute;" routerLink="/add-product" class="isearch_btn" ><li class="bx bx-plus-circle"></li>Add Item </a>
                            </div> 
                            <table>
                
                                <tr class="border-bottom" *ngFor="let data of appservice.cart_item;let i = index">
                
                                    <td style="width: 50px;  padding-left: 5px;
                                    padding-right: 0px;">
                                    <span> {{i+1}}</span>
                                       </td>
                                    <td style=" padding-left: 5px;
                                    padding-right: 2px;">
                                        <div class="name">
                                            {{data.ITEM_NAME}}
                                        </div>
                                        <div class="desc">
                                            {{data.DESCRIPTION}}
                                        </div>
                
                                        <div (click)="remove(data)" class="del" style="color: darkred;padding-left: 5px;padding-right: 5px;">
                                            <li  class="bx bx-trash"> </li><span class="text"></span>
                                        </div>
                
                
                                    </td>
                                    <td style="text-align: right; ">
                                        <div class="price">
                                            ₹ {{data.SALE_RATE}}
                                        </div>
                                        <div class="mrp">
                                            MRP : <span class="ma">₹ {{data.MRP}}</span>
                                        </div>
                
                                        <div class="qty">
                
                                            <li (click)="remove_item(data)" class="bx bx-minus-circle icons"></li>
                                            <span class="text">{{data.Qty}}</span>
                                            <li (click)="add_item(data)" class="bx bxs-plus-circle icons"></li>
                                        </div>
                
                                    </td>
                
                                </tr>
                            </table>
                
                        </div>
                
                    </div>
                    <div class="col-md-4 mpx" *ngIf="appservice.cart_item.length>0">
                
                
                        <div class="cart-summary">
                
                        <div class="head_text">Order summary</div> 
                            <table>
                
                                <tr>
                
                                    <td>
                                        <div class="name">
                                            Sub Total
                                        </div>
                
                
                
                                    </td>
                                    <td style="text-align: right; ">
                                        <div class="price">
                                            ₹ {{appservice.C_Sub_Total}}
                                        </div>
                
                
                                    </td>
                
                                </tr>
                
                
                       
                
                                <tr>
                
                                    <td style="    border-bottom: 1px dotted #dee2e6 !important;">
                                        <div class="name">
                                            Tax
                                        </div>
                
                
                
                                    </td>
                                    <td style="text-align: right;   border-bottom: 1px dotted #dee2e6 !important;">
                                        <div class="price">
                                            ₹ {{appservice.C_Tax}}
                                        </div>
                
                
                                    </td>
                
                                </tr>
                
                
                
                                <tr>
                
                                    <td>
                                        <div class="name">
                                            Total Amount Payable
                                        </div>
                
                
                
                                    </td>
                                    <td style="text-align: right; ">
                                        <div class="price">
                                            ₹ {{appservice.C_Total_Payable}}
                                        </div>
                
                
                                    </td>
                
                                </tr>
                
                                
                
                
                                <tr>
                
                                    <td colspan="2" style="text-align: center;" >
                                        
                                        <a  [class.disabled]="isload" style="border-radius: 0px;color: white;" (click)="Place_Order()"  class="btn btn-danger btn-block">
                                            
                                            <span *ngIf="isload==true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                                            PLACE ORDER</a>
                        
                        <a style="border-radius: 0px;"  routerLink="/add-product"  class="btn btn-secondary btn-block">ADD MORE</a>

                                        
                
                                    </td>
                                   
                
                                </tr>
                
                
                            </table>
                
                        </div>
                    </div>
                
                
                
                </div>

            </div>
        </div>
     </div>
</div>




