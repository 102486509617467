<div class="row">
    <div class="col-md-12 nopad">
      <div class="card content ">
  
        <div class=" Head ">
  
  
  
          <div class="card-header  d-flex justify-content-between align-items-center">
            <h3>Bill Generation</h3>
  
            <div class="dropdown">
  
              <a (click)="appservice.back()" style="font-size: 20px;font-weight: 900;cursor: pointer;color:black">
                <b>X</b>
              </a>
  
  
            </div>
          </div>
  
        </div>
  
        <div class="content_body">
  
  
  
       
  
  
            <div class="row">
  
              <div class="col-sm-6">
  
                <div class="form-table table-responsive">
                  <table class="table table-bordered">
  
  
                    <tbody>
                     
  
  
                      <tr>
                        <td>Month<span class="text-danger"></span></td>
                        <td>
                          <input class="form-control ng-touched" type="date" [(ngModel)]="appservice.S_From" required
                            name="S_From" #S_From="ngModel">
                         
                        </td>
                      </tr>
  
  
  
                    </tbody>
  
                  </table>
  
                  <div class="invoice-btn-box text-right " style="padding-bottom:20px">
              
                    <button class="btn btn-info rounded-pil" (click)="appservice.Generate_Bill()" type="button" [disabled]="appservice.isload" > 
                    
                    
                      <span *ngIf="appservice.isload==true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  
  
                      <i  *ngIf="appservice.isload==false" class='bx bx-paper-plane'></i>
                    Generate </button> &nbsp;&nbsp;
                 <button class="btn btn-secondary rounded-pil " type="button" (click)="appservice.back()">Cancel</button>  &nbsp;&nbsp;
                </div>
  
                </div>
              </div>
            </div>
          
        </div>
      </div>
    </div>
  </div>
  