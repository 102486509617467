import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-deleted-item-details',
  templateUrl: './deleted-item-details.component.html',
  styleUrls: ['./deleted-item-details.component.scss']
})
export class DeletedItemDetailsComponent implements OnInit {

  constructor(public appservice:AppService) { 

    this.appservice.Delete_Order_Details();
  }
  export_excel()
  {
 
   this.appservice.Excel_Data.Header=this.appservice.Billwise_out_Export;
   this.appservice.Excel_Data.items=this.appservice.Billwise_Out
   this.appservice.Excel_Data.Report_Name="Billwise Out"
   this.appservice.export_excel();
 
  }


  export_pdf(data)
 {

  this.appservice.Excel_Data.Header=this.appservice.Billwise_out_Export;
  this.appservice.Excel_Data.Total_Row= "Total Rows : "+this.appservice.length_of(data);
  this.appservice.Excel_Data.Total_Amount="Total Amount : "+this.appservice.sum_of(data,'Amount');
  this.appservice.Excel_Data.items=this.appservice.Billwise_Out
  this.appservice.Excel_Data.Report_Name="Billwise Out"
  this.appservice.export_pdf();
  }

  ngOnInit(): void {
  }

}
