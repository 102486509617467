<div class="row">
    <div class="col-md-12 nopad">
      <div class="card content ">
  
        <div class=" Head ">
  
  
  
          <div class="card-header  d-flex justify-content-between align-items-center">
            <h3>Add  Document</h3>
  
            <div class="dropdown">
  
              <a (click)="Back()" style="font-size: 20px;font-weight: 900;cursor: pointer;color:black">
                <b>X</b>
              </a>
  
  
            </div>
          </div>
  
        </div>
  
        <div class="content_body">
  
  
  
          <form #addForm="ngForm" (ngSubmit)="addData(addForm)" (keydown.enter)="$event.preventDefault()" novalidate>
  
  
  
            <div class="row">
  
              <div class="col-sm-12">
  
                <div class="form-table table-responsive">
                  <table class="table table-bordered">
  
  
                    <tbody>
                      <tr class="hidden">
                        <td>ID <span class="text-danger">*</span></td>
                        <td><input class="form-control" type="text" [(ngModel)]="add.ID" name="ID" #ID="ngModel" required>
                        </td>
                      </tr>
  
  
                      <tr>
                        <td>Head<span class="text-danger">*</span></td>
                        <td>
                          <input class="form-control ng-touched" type="text" [(ngModel)]="add.Name" required
                            name="Name" #Name="ngModel">
                          <div *ngIf="addForm.submitted && Name.invalid" class="invalid-feedback d-block">
                            <div class="error">Head Should not Empty</div>
                          </div>
                        </td>
                      </tr>
  
  
                      <tr>
                        <td>Details</td>
                        <td>
                            
                            <textarea class="form-control" rows=5 [(ngModel)]="add.Description" required
                            name="Description" #Description="ngModel"></textarea>
                         
                        </td>
                      </tr>
  
  
                      <tr colspan="2" >
                        <td>Image</td>
                        <td>
                          <p-fileUpload name="myfile[]" url="{{server}}" (onUpload)="onUpload($event)"
                            multiple="multiple" maxFileSize="1000000">
                            <ng-template pTemplate="content">
                              <ul *ngIf="uploadedFiles.length">
                                <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                              </ul>
                            </ng-template>
                          </p-fileUpload>

                        </td>
                      </tr>
  
                    </tbody>
  
                  </table>
  
                  <div class="invoice-btn-box text-right " style="padding-bottom:20px">
              
                    <button class="btn btn-info rounded-pil" type="submit"> <i class='bx bx-paper-plane'></i> Save </button> &nbsp;&nbsp;
                 <button class="btn btn-secondary rounded-pil " type="button" (click)="Back()">Cancel</button>  &nbsp;&nbsp;
                </div>
                <div class="col-sm-4">

                  <div class="form-table table-responsive">
                      <table class="table table-bordered">
                          <tbody>
                           

                              <tr >
                                  <td>
                                   
                                    <img 
                                    [src]="get_image()"

                                    alt="First slide">
          
                                  </td>
                                </tr>
                            


                          </tbody>
                      </table>

                      

                  </div>
              </div>

                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  