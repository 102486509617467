<div class="row">
    <div class="col-md-12 nopad">
       <div class="col-md-12 nopad mobile_hide">
  
  
          <div class="w_79" style="position: fixed;z-index: 99; ">
            <div class="tabs-header border-bottom">
              <ul>
  
                <li  > <a  style="display: inline-flex;"> <span style="    font-size: 23px; color:#4788ff" class="bx bx-group"></span> Customer Details</a></li>
             
  
              </ul>
            </div>
  
  
            <div class="right-corner">
  
              <div class="dropdown" style="display: inline-flex;">
  
                <a class="dropdown-toggle   addbtn1" (click)="addReset()" data-toggle="tooltip" data-placement="bottom"
                title="Add New">
  
                <i class='bx bx-list-plus'></i>
              </a>
              <a class="dropdown-toggle  serch_icon   mshow"  data-toggle="tooltip" data-placement="bottom"
              title="search" >
             
                <i style="    font-size: 20px;" class='bx bx-search'></i> 
              </a>


              <a  style="padding-left: 10px;padding-right: 10px;" class="dropdown-toggle " (click)="appservice.get_Ledger_Master()" data-toggle="tooltip" data-placement="bottom"
              title="Refresh">

              <i class='bx bx-rotate-left'></i>
            </a>
  
  
                <button class="dropdown-toggle" type="button" data-toggle="dropdown" title="more" aria-haspopup="true"
                  aria-expanded="false">
                  <i class='bx bx-filter'></i>
                </button>
  
                <div class="dropdown-menu" style="width: 325px;">
                  <div style="background-color: teal; color: white; width: 100%; ">
                    &nbsp;&nbsp;Search</div>
                  <table>
  
  

                    <tr>
                      <td style="width: 80px;">Area </td>
                      <td>


                          
                          <select style="height: 30px;"  placeholder="Select Area" class="area form-control"  [(ngModel)]="appservice.S_Area" name="Area" #Area="ngModel" style="width:100%"   aria-hidden="true">

                              <option  value="All">
                                  All
                              </option>
                              <option *ngFor="let data of appservice.Area_Row" value={{data.label}}>
                                  {{data.label}}
                              </option>
                          
                                </select>

                      </td>
                  </tr>


                    <tr>
                      <td style="width: 80px;">Order by </td>
                      <td>
  
                        <select class="area form-control form-control-sm" [(ngModel)]="appservice.Ledger_Order_by"
                          name="Ledger_Order_by" #Ledger_Order_by="ngModel" style="width:100%" aria-hidden="true">
  
                          <option value="Area,Order">
                            Area
                          </option>
  
                          <option value="Ledger_Name">
                            Name
                          </option>
  
                          <option value="created_date desc">
                            Created Date
                          </option>
  
  
                        </select>
  
                      </td>
                    </tr>
  
  
                    <tr>
                      <td> </td>
                      <td><button type="button" (click)="appservice.get_Ledger_Master()"
                          class="btn btn-info btn-sm">Search</button></td>
                    </tr>
  
                  </table>


                  <div  class="search_Footer border-top" style="color: green;"> 
                    <div (click)="export_excel(dt)"  class="buttons">
                      <span><li class="bx bx-download"></li></span>
                     <span> Export Excel</span>
                     
                    </div>

                   
                    <div (click)="export_pdf(dt)"  class="buttons " style="color: red;">
                      <span><li class="bx bx-download"></li></span>
                     <span> Export PDF</span>
                     
                    </div>


                </div>
               

  
                </div>
  
              </div>
  
            </div>
  
          </div>
        </div>
      
  
    
        <div class="col-md-12 nopad">
  
  
          <div class="spin_block" *ngIf="appservice.isload">
  
            <div class="d-flex justify-content-center">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
  
          </div>
          <div class="search-bar_Ext mhide  show_bar " style="right: 110px;">
        
            <li  class="bx bx-left-arrow-alt mshow  search_back"></li>

                <input  type="text"  (input)="dt.filterGlobal($event.target.value, 'contains')" 
                class="search-form-control text"
                placeholder="Search...">
    
          </div>
  
  
          <div class="m_2 res_table">
            <p-table responsiveLayout="scroll"#dt   (onFilter)="handleFilter($event,dt.totalRecords)" 
             [globalFilterFields]="['Ledger_Name','Area','Phone_Number','GSTIN','Address1','Status']"
              class="table table-striped custom-table" [value]="appservice.Ledger_Master_Rows" 
              selectionMode="single">
              <ng-template pTemplate="header">
                <tr>
  
  
                  <th>#</th>
                  
                  
                  <th [pSortableColumn]="'Ledger_Name'"  >Customer Name </th>
                  <th [pSortableColumn]="'Short_name'" class="mhide"  >Nick Name </th>
                  <th [pSortableColumn]="'GSTIN'"class="mhide " >Vc Number</th>
                  <th [pSortableColumn]="'Address1'"class="mhide hidden " >Address</th>
                  <th [pSortableColumn]="'Phone_Number'"class="mhide" >Phone No</th>
                  <th >Amount</th>
                  <th [pSortableColumn]="'Area'">&nbsp;&nbsp;Area&nbsp;&nbsp;</th>
                  <th class="mhide" [pSortableColumn]="'Status'">Status</th>
                  
                  <th></th>
  
  
                </tr>
  
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr>
                <td>{{rowIndex+1}}</td>
             
                  <td>{{rowData.Ledger_Name}}
                    <span class="mshow text-muted">
                      {{rowData.Short_name}}
                    </span>

                    <span class="mshow text-muted">
                      {{rowData.Phone_Number}}
                    </span>
                  </td>
                  <td class="mhide">{{rowData.Short_name}}</td>
                  <td class="mhide">{{rowData.GSTIN}}</td>
                  <td class="mhide hidden">{{rowData.Address1}}</td>
                  <td class="mhide">{{rowData.Phone_Number}}</td>

                  <td  style="text-align:left;" >{{rowData.Amount}}</td>
                  
                  <td>{{rowData.Area}} </td>
                  <td class="mhide">{{rowData.Status}} </td>
  
         
                  <td style="text-align: center;">
  
                    <div class="dropdown">
                      <button class="dropdown-toggle" type="button" data-toggle="dropdown" title="more"
                        aria-haspopup="true" aria-expanded="false">
                        <i class='bx bxs-badge-check' style="color: #4788ff;"></i>
                      </button>
                      <div class="dropdown-menu">
                      
                        <a class="dropdown-item d-flex align-items-center" (click)="onEdit(rowData)">
                          <i class='bx bx-edit-alt'></i> Edit
                        </a>
                        <a class="dropdown-item d-flex align-items-center" (click)="onDelete(rowData.ID)">
                          <i class='bx bx-trash'></i> Delete
                        </a>
  
                      </div>
                    </div>
  
                  </td>
  
                </tr>
              </ng-template>
            </p-table>
  
  
  
  
          </div>
        </div>
      
  
     
        <div class="col-md-12 nopad">
  
          <div class="w_79" style="  bottom: 0;
              font-weight: 700;
              color: #333333;
              background-color: #f4f4f4;
              padding: 0.571em 0.857em;
              position: fixed;
              bottom: 0;
              
              border: 1px solid #c8c8c8;">
              
              
              <table style="width: 100%;"><tr>
                <td style="color: darkslategray;">Total Rows : <span *ngIf="count!=0">{{count}} </span> <span *ngIf="count==0">{{appservice.Ledger_Master_Rows.length}}</span> </td>
                <td style="text-align: right;     color: crimson;"></td>
            </tr></table>
  
              </div>
        </div>
     
    </div>
  </div>


