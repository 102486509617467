import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
@Component({
  selector: 'app-reference-group',
  templateUrl: './reference-group.component.html',
  styleUrls: ['./reference-group.component.scss']
})
export class ReferenceGroupComponent implements OnInit {

  constructor(public confirmationService: ConfirmationService,public appservice:AppService, private router: Router, private route: ActivatedRoute) { 
    this.appservice.get_Reference_Group(); 
    
      }
      addReset() {
        this.appservice.isadd = "0";
        return this.router.navigate(['/add-reference-group']);
      }
    
     onDelete(item) {
          this.confirmationService.confirm({
            message: 'Are you sure that you want to delete press Yes?',
            accept: () => {
              this.Delete(item)
            }
          });
      }
      Delete(item) {
        this.appservice.get("Api/Setting/delete_Reference_Group?ID=" + item).subscribe((res: any) => {
          var index = this.appservice.Reference_Group_Rows.findIndex(function (items, i) {
            return items.ID === item
          });
          if (index > -1) {
            this.appservice.Reference_Group_Rows.splice(index, 1);
          }
          this.appservice.Reference_Group_Rows = this.appservice.Reference_Group_Rows;
          this.appservice.get_Reference_Group();
        });
      }
     
      ngOnInit(): void {
      }
    
    }
    