import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-order-entry',
  templateUrl: './order-entry.component.html',
  styleUrls: ['./order-entry.component.scss']
})
export class OrderEntryComponent implements OnInit {


  isload:boolean=false;
 
  constructor(private _location: Location, public appservice: AppService, private toastr: ToastrService, private http: HttpClient, private router: Router, private route: ActivatedRoute)  
  { 
   appservice.page_Name="Order_Entry";
   this.appservice.isEdit=false;
   this.get_Order_No();

   this.appservice.Selected_Customer={};
   this.appservice.item_Category_="";
   this.appservice.Stcok_Row=[];
  }



  
  search()
  {

  this.appservice.getc("Api/Invoice/get_Order_details").subscribe((res: any) => { 
  this.appservice.order_Rows = JSON.parse(res).record; 
    try{
        this.appservice.lenth=this.appservice.order_Rows.length;
        }catch
        {
        this.appservice.lenth=0;
        }

  }); 

  }


  get_Order_No() {
    if (this.appservice.isEdit == false) {
      this.appservice.getc("Api/Invoice/get_Order_No").subscribe((res: any) => {
        this.appservice.Order_No = res;

      });
    }
  } 

  

   s_item:any={}
   remove_item(data)
   {
     var qty_1=-1;
    this.s_item=this.appservice.Item_Master_Rows.filter(x =>x.ID==data.ITEM_ID)[0];
    this.appservice.add_item(this.s_item,qty_1)
   }

   add_item(data)
   {
    var qty_1=1;
    this.s_item=this.appservice.Item_Master_Rows.filter(x =>x.ID==data.ITEM_ID)[0];

   
    this.appservice.add_item(this.s_item,qty_1)

   }


   remove(data)
   {
    var qty_1=1;
    this.s_item=this.appservice.Item_Master_Rows.filter(x =>x.ID==data.ITEM_ID)[0];
    this.appservice.add_item(this.s_item,Number(data.Qty)*-1)

   }

  
  
   public rows = [];
   public add: any = {
 
     
       'ID': '',
       'LEDGER_ID': '',
       'BILL_NO': '',
       'BILL_DATE': '',
       'BILL_MODE': '',
       'CUSTOMER_ID': '',
       'CUSTOMER_NAME': '',
       'ADDR1': '',
       'ADDR2': '',
       'ADDR3': '',
       'ADDR4': '',
       'ADDR5': '',
       'PHONE': '',
       'GSTNO': '',
       'STATE': '',
       'STATECODE': '',
       'S_CUSTOMER_NAME': '',
       'S_ADDR1': '',
       'S_ADDR2': '',
       'S_ADDR3': '',
       'S_ADDR4': '',
       'S_ADDR5': '',
       'S_PHONE': '',
       'S_GSTNO': '',
       'S_STATE': '',
       'S_STATECODE': '',
       'TAX_GROSSAMT': '0',
       'TOTAL_DISC': '0',
       'TAX_YESNO': '',
       'TAXABLE_AMT': '0',
       'CGST_PER': '0',
       'SGST_PER': '0',
       'IGST_PER': '0',
       'CGST_AMT': '0',
       'SGST_AMT': '0',
       'IGST_AMT': '0',
       'TOTAL_TAX': '0',
       'BILL_AMT': '0',
       'Received_AMT':'0',
       'Received_Bank':'0',
       'items':[],
       'CREATED_BY': '',
       'CREATED_DATE': '',
       'STATUS': '',
     };
   
   public addValidation: boolean = false;
   headers;
   data;
   isadd = "0";
 
   Place_Order() {
 
 
 
 
 
   this.add.ID=0;
   this.add.LEDGER_ID=this.appservice.Selected_Customer.Ledger_Name;

   if(this.appservice.isEdit==true)
   {

    this.add.BILL_NO=this.appservice.Order_No;
    
   }
   else
   {

    this.add.BILL_NO="0";
   }

   
   this.add.BILL_DATE="";
   this.add.BILL_MODE="";
   this.add.CUSTOMER_ID=this.appservice.Selected_Customer.ID;
   this.add.CUSTOMER_NAME=this.appservice.Selected_Customer.Ledger_Name;
   this.add.ADDR1=this.appservice.Selected_Customer.Address1;
   this.add.ADDR2=this.appservice.Selected_Customer.Address2;
   this.add.ADDR3=this.appservice.Selected_Customer.Address3;
   this.add.ADDR4=this.appservice.Selected_Customer.Address4;

   this.add.Area=this.appservice.Selected_Customer.Area;

   
   
   this.add.PHONE=this.appservice.Selected_Customer.Phone_Number;
   this.add.GSTNO=this.appservice.Selected_Customer.GSTIN;
   this.add.STATE=this.appservice.Selected_Customer.State;
   
   this.add.TAX_GROSSAMT=this.appservice.C_Sub_Total;
   this.add.TOTAL_DISC="0";
      
   this.add.TAXABLE_AMT=this.appservice.C_Sub_Total;
   this.add.CGST_PER="0";
   this.add.SGST_PER="0";
   this.add.IGST_PER="0";
   this.add.CGST_AMT="0";
   this.add.SGST_AMT="0";
   this.add.IGST_AMT="0";
   this.add.TOTAL_TAX=this.appservice.C_Tax;
   this.add.BILL_AMT=this.appservice.C_Total_Payable;
 
   this.add.Company_Name=this.appservice.Selected_Company.CM_Name;
   this.add.Company=this.appservice.Company; 

   this.add.CREATED_BY=this.appservice.Sales_Person; 

   this.add.items=this.appservice.cart_item;
       this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

       this.isload=true;
       this.http.post(this.appservice.Server_URL + 'api/Invoice/insert_Order', this.add, { headers: this.headers })
         .subscribe(
           (val: string) => {
             
       this.isload=false;
             if (val == "True") {
               this.toastr.success("Order Placed Successfully", 'Msg');
               
               this.search()
               this.appservice.back();
               this.appservice.Order_No="0";
               this.appservice.isEdit=false;
             }
             else {
               this.toastr.error(val, "Error", { timeOut: 3000 });
             }
           },
           response => {
             this.toastr.error('Error ', response, {
               timeOut: 3000
             });
           });
     
   }
   
 
 
   ngOnInit(): void {
    this.appservice.Item_Master_Rows=this.appservice.Perment_Item_Master_Row;
   }
 
 }
 