<div class="row">

    <div class="col-md-6 mpx">

        <div class="cart-summary">
            <div class="head_text">Delivery Location</div>
            <table style="font-weight: 17px;">


                <tr style="padding: 30px;">
                    <td style=" vertical-align:top">
                        <i class="bx bxs-user-circle" style="font-size: 22px;
                        color: #616770;
                        -webkit-transition: .5s;
                        transition: .5s;"></i>
                    </td>
                    <td>
                        <div class="">{{appservice.Selected_Customer.Ledger_Name}} </div>


                    </td>
                </tr>


                <tr>
                    <td style=" vertical-align:top" width="40px">
                        <i class="bx bxs-location-plus" style="font-size: 22px;
                        color: #616770;
                        -webkit-transition: .5s;
                        transition: .5s;"></i>
                    </td>
                    <td>
                        <div class="">{{appservice.Selected_Customer.Address1}} </div>
                        <div class="">{{appservice.Selected_Customer.Address2}} </div>
                        <div class="">{{appservice.Selected_Customer.Address3}} </div>
                        <div class="">{{appservice.Selected_Customer.Address4}} </div>
                        <div class="">{{appservice.Selected_Customer.City}} </div>
                        <div class="">{{appservice.Selected_Customer.State}} </div>
                        <div class="">{{appservice.Selected_Customer.Pincode}} </div>

                    </td>
                </tr>

                <tr>
                    <td style=" vertical-align:top">
                        <i class="bx bxs-phone-call" style="font-size: 22px;
                        color: #616770;
                        -webkit-transition: .5s;
                        transition: .5s;"></i>
                    </td>
                    <td>
                        <div class="">{{appservice.Selected_Customer.Phone_Number}} </div>


                    </td>
                </tr>

                <tr>
                    <td style=" vertical-align:top">
                        <i class="bx bx-mail-send" style="font-size: 22px;
                        color: #616770;
                        -webkit-transition: .5s;
                        transition: .5s;"></i>
                    </td>
                    <td>
                        <div class="">{{appservice.Selected_Customer.EmailID}} </div>


                    </td>
                </tr>

                <tr>
                    <td style=" vertical-align:top">

                    </td>
                    <td>
                        <div style=" color :red"><a routerLink="/edit-profile"> <i class='bx bx-edit-alt'></i> Edit
                                Delivery Location</a> </div>


                    </td>
                </tr>
            </table>



        </div>

    </div>
    <div class="col-md-5 mpx">


        <div class="cart-summary">

            <div class="head_text">Order summary</div>
            <table>

                <tr>

                    <td>
                        <div class="name">
                            Sub Total
                        </div>



                    </td>
                    <td style="text-align: right; ">
                        <div class="price">
                            ₹ {{appservice.C_Sub_Total}}
                        </div>


                    </td>

                </tr>

                <tr>
                <td>
                        <div class="name">
                            Delivery Charges
                        </div>
                    </td>
                    <td style="text-align: right; ">
                        <div class="price">
                            ₹ {{appservice.C_Delivery_Charges}}
                        </div>


                    </td>

                </tr>



                <tr>

                    <td style="    border-bottom: 1px dotted #dee2e6 !important;">
                        <div class="name">
                            Tax
                        </div>

                    </td>
                    <td style="text-align: right;   border-bottom: 1px dotted #dee2e6 !important;">
                        <div class="price">
                            ₹ {{appservice.C_Tax}}
                        </div>
                    </td>

                </tr>



                <tr>

                    <td>
                        <div class="name">
                            Total Amount Payable
                        </div>



                    </td>
                    <td style="text-align: right; ">
                        <div class="price">
                            ₹ {{appservice.C_Total_Payable}}
                        </div>


                    </td>

                </tr>

                <tr>

                    <td>
                        <div class="name" style="color: #82b70d;">
                            Your Total Savings
                        </div>



                    </td>
                    <td style="text-align: right; color: #82b70d; ">
                        <div class="price" Style="font-weight: 500;">
                            ₹ {{appservice.C_Savings}}
                        </div>


                    </td>

                </tr>

                <tr >
                    <td>Bill Mode</td>
                    <td>
                        
                        <select class="select2-option form-control " [(ngModel)]="appservice.Bill_Mode" name="Bill_Mode"
                            #Bill_Mode="ngModel" style="width:100%" aria-hidden="true">

                            <option value="Cash">Cash</option>
                            <option value="Credit">Credit</option>

                        </select>
                    </td>
                </tr>


                <tr [className]="appservice.Bill_Mode=='Cash' ? '' : 'hidden'">
                    <td>Pay Mode</td>
                    <td  style="text-align: center;">


                        <select class="select2-option form-control " [(ngModel)]="appservice.Pay_Mode" name="Pay_Mode"
                            #Pay_Mode="ngModel" style="width:100%" aria-hidden="true">

                            <option *ngFor="let data of Pay_Mode_" value={{data.value}}>
                                {{data.label}}
                            </option>

                        </select>





                    </td>

                </tr>


                
                <tr [className]="appservice.Bill_Mode=='Cash' ? '' : 'hidden'">
                    <td style="width: 150px;">Received Amount</td>
                    <td><input class="form-control" style="text-align:right" type="number"
                        [(ngModel)]="add.Received_AMT" name="Received_AMT" #Received_AMT="ngModel"></td>
                </tr>

            <tr [className]="appservice.Pay_Mode==appservice.Cash_ID ? 'hidden' : ''">

                <td colspan="2" style="padding: 0px;">
                <table style="width: 100%;">
                    <tr  >
                        <td style="width: 150px;">Receiving Bank</td>
                        <td>
                            
                            <select class="select2-option form-control " [(ngModel)]="appservice.Receiving_Bank" name="Receiving_Bank"
                            #Receiving_Bank="ngModel" style="width:100%" aria-hidden="true">
    
                            <option *ngFor="let data of Banks_" value={{data.value}}>
                                {{data.label}}
                            </option>
    
                        </select>
                    
                    </td>
                    </tr>
    
                    <tr [className]="appservice.Pay_Mode==appservice.Cheque_ID ? '' : 'hidden'">
                        <td>Cheque No </td>
                        <td> <input class="form-control" type="text" [(ngModel)]="appservice.Cheque_No"
                            name="Cheque_No" #Cheque_No="ngModel"></td>
                    </tr>
    
                    <tr [className]="appservice.Pay_Mode==appservice.Cheque_ID ? '' : 'hidden'" >
                        <td>Cheque Date</td>
                        <td><input class="form-control" type="text" [(ngModel)]="appservice.Cheque_Date"
                            name="Cheque_Date" #Cheque_Date="ngModel"></td>
                    </tr>
    
                    <tr [className]="appservice.Pay_Mode==appservice.Card_ID ? '' : 'hidden'">
                        <td>Card Charge</td>
                        <td><input class="form-control" type="text" [(ngModel)]="appservice.Card_Charge"
                            name="Card_Charge" #Card_Charge="ngModel"></td>
                    </tr>
    
                    <tr>
                        <td>Remarks</td>
                        <td> <input class="form-control" type="text" [(ngModel)]="appservice.Remarks" name="Remarks"
                            #Remarks="ngModel"></td>
                    </tr>
                </table>
            </td>
            </tr>

             
                <tr>
                    <td colspan="2" style="text-align: center;">



                        <a  [class.disabled]="isload" style="border-radius: 0px;color: white;" (click)="Place_Order()"
                            class="btn btn-danger btn-block">Save Invoice
                        
                                           
                        <span *ngIf="isload==true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                        
                        </a>

                        <a style="border-radius: 0px;" (click)="appservice.back()"
                            class="btn btn-secondary btn-block">Back</a>



                    </td>


                </tr>


            </table>

        </div>
    </div>



</div>