<div class="row" >
        <div class="col-md-12" style="text-align: center;">

                <span style="
                font-size: 18px;
                color: #e01414!important;
                font-weight: 700; padding:20px "><br>Welcome,{{appservice.Current_User.UM_Full_Name}}!</span>
        </div>

</div>

<div class=" row home" style="padding-bottom: 1200px;">



        <div class="col-6 col-md-3  mbox" >
                <a routerLink="/report/Outstanding-customer">

                        <div class="ibox">


                                <li class="bx bxs-user"></li>
                        </div>

                        <span>Customer</span>
                </a>
        </div>

        <div class="col-6 col-md-3 mbox">
                <a routerLink="/transaction/add-payment">
                        <div class="ibox">

                                <li class="bx bxs-receipt"></li>
                        </div>
                        <span>Receipt Entry</span>
                </a>
        </div>

        <div class="col-6 col-md-3 mbox">
                <a routerLink="/transaction/payment-details">
                        <div class="ibox">

                                <li class="bx bxs-badge-dollar"></li>
                        </div>
                        <span>Receipt Details</span>
                </a>
        </div>

        <div class="col-6 col-md-3 mbox">

                <a >
                        <div class="ibox">

                                <li class="bx bx-help-circle"></li>

                        </div>
                        <span>Help</span>
                </a>
        </div>

</div>