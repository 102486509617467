import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-home-collection',
  templateUrl: './home-collection.component.html',
  styleUrls: ['./home-collection.component.scss']
})
export class HomeCollectionComponent implements OnInit {

  constructor(public appservice:AppService) { }

  ngOnInit(): void {
  }

}
