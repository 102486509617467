import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-add-items',
  templateUrl: './add-items.component.html',
  styleUrls: ['./add-items.component.scss']
})
export class AddItemsComponent implements OnInit {


  Category_ = "All";
  constructor(private _location: Location, public appservice: AppService, private toastr: ToastrService, private http: HttpClient, private router: Router, private route: ActivatedRoute) {
    appservice.item_Category_="All"
  }


  get_filter_data() {

    this.get_Items(this.appservice.item_Category_);

  }

  Category="";
  get_Items(data) {
    this.Category = data;

    console.log(data);
    console.log(this.appservice.Perment_Item_Master_Row.filter(e => e.Category == data));
    if (data == "All") {
      this.appservice.Item_Master_Rows = this.appservice.Perment_Item_Master_Row;

    }
    else {
      this.appservice.Item_Master_Rows = this.appservice.Perment_Item_Master_Row.filter(e => String(e.Category).toLowerCase() == String(data).toLowerCase());
    }
  }

  txt_search = "";
  ngOnInit(): void {
  }


  public rows = [];
  public add: any = {};

  Total = "0.00";


  add_One(data)
  {
    data.Order_Qty=Number(data.Order_Qty)+1;
    this.add_row(data);
  }

  remove_One(data)
  {
    data.Order_Qty=Number(data.Order_Qty)-1;
    this.add_row(data);
  }
  Search_text_="";


  search_data(data)
  {
    if (data == "") {
      this.appservice.Item_Master_Rows=this.appservice.Perment_Item_Master_Row;
  
     }
     else {
      this.appservice.Item_Master_Rows=this.appservice.Perment_Item_Master_Row;
      this.appservice.Item_Master_Rows=this.appservice.Perment_Item_Master_Row.filter(e => e.Item_Name.toLowerCase().includes(data.toLocaleLowerCase()));
     }
  }
  add_row(data) {


    data.Order_Rate=data.Rate;
    var index = this.appservice.Temp_Order_Row.findIndex(function (item, i) {
      return (item.ID == data.ID)
    });


    if (index > -1) {
      this.appservice.Temp_Order_Row.splice(index, 1);
    }
    this.Total = (this.appservice.Temp_Order_Row.reduce((sum, current) => sum + ((parseFloat(current.Order_Qty)) * (parseFloat(current.Order_Rate))), 0)).toFixed(2);



    if (data.Order_Qty == "") {
      data.error = "";
      return;
    }


    if (Number(data.Order_Qty) == 0) {
      data.error = "";
      return;
    }


    if (Number(data.Order_Qty) < 0) {
      data.error = "Not Valid Qty ";
      return;
    }


    data.error = "";


    if (Number(data.Order_Qty) > 0 && Number(data.Order_Rate) > 0) {
      this.appservice.Temp_Order_Row.push(data);

    }

    this.Total = (this.appservice.Temp_Order_Row.reduce((sum, current) => sum + ((parseFloat(current.Order_Qty)) * (parseFloat(current.Order_Rate))), 0)).toFixed(2);


  }

  public addValidation: boolean = false;
  headers;
  data;
  isadd = "0";
  isload: boolean = false;



  next() {

    this.appservice.Details_Row=[];
    for (var i = 0; i < this.appservice.Temp_Order_Row.length; i++) {

      this.get_by_Code(this.appservice.Temp_Order_Row[i]["ID"], this.appservice.Temp_Order_Row[i]["Order_Qty"], this.appservice.Temp_Order_Row[i]["Order_Rate"], this.appservice.Temp_Order_Row[i]["Uni_Code"], this.appservice.Temp_Order_Row[i]["Landing_Cost"]);
    }
    this.appservice.isadd_Page=true;
    this.appservice.back();

  }
  temp_data: any = {};

  get_by_Code(data, Qty, Rate, Uni_Code, Landing_Cost) {

    var Sales_Temp_Row: any = {};
    this.temp_data = this.appservice.Item_Master_Rows.filter(e => e.ID == data)[0];


    try {
      Sales_Temp_Row.Item_ID = this.temp_data.ID;
      Sales_Temp_Row.Item_Code = this.temp_data.Item_Code;
      Sales_Temp_Row.Item_Name = this.temp_data.Item_Name;
      Sales_Temp_Row.Description = this.temp_data.Description;
      Sales_Temp_Row.UOM = this.temp_data.UOM;
      Sales_Temp_Row.HSN_Code = this.temp_data.HSN_Code;
      Sales_Temp_Row.MRP = this.temp_data.MRP;
      Sales_Temp_Row.GST_Per = this.temp_data.GST_Per;
      Sales_Temp_Row.Unit_Price = Rate;

      Sales_Temp_Row.Bag_Qty = this.temp_data.Bag_Qty;
      Sales_Temp_Row.Pcs = Qty;
      Sales_Temp_Row.Qty = Qty;

      Sales_Temp_Row.Uni_Code = Uni_Code;

      Sales_Temp_Row.Disc_Per = "0";
      Sales_Temp_Row.Disc_Amt = "0";
      Sales_Temp_Row.Free = "0";


      Sales_Temp_Row.Landing_Cost ="0";




      var GST_Type = "local";
      try {
        GST_Type = this.appservice.header_Row.GST_Type;
      }
      catch {
        GST_Type = "local"
      }


      if (String(GST_Type).toLowerCase() == "intra") {

        Sales_Temp_Row.SGST_Per = "0.00";
        Sales_Temp_Row.CGST_Per = "0.00";
        Sales_Temp_Row.IGST_Per = Sales_Temp_Row.GST_Per;

      }
      else {
        Sales_Temp_Row.SGST_Per = (Number(Sales_Temp_Row.GST_Per) / 2).toFixed(2);
        Sales_Temp_Row.CGST_Per = (Number(Sales_Temp_Row.GST_Per) / 2).toFixed(2);
        Sales_Temp_Row.IGST_Per = "0.00";

      }

      Sales_Temp_Row.Qty = (Number(Sales_Temp_Row.Pcs) * Number(Sales_Temp_Row.Bag_Qty));

      var Disc_Type = "percentage";
      try {
        Disc_Type = this.appservice.header_Row.Disc_Type;
      }
      catch {
        Disc_Type = "percentage"
      }

      if (String(Disc_Type).toLowerCase() == "amount") {
        Sales_Temp_Row.Disc_Per = ((Number(Sales_Temp_Row.Disc_Amt) / Number(Sales_Temp_Row.Unit_Price)) / 100).toFixed(2);

      }
      else {
        Sales_Temp_Row.Disc_Amt = (((Number(Sales_Temp_Row.Unit_Price) / 100) * Number(Sales_Temp_Row.Disc_Per))).toFixed(2);
      }


      Sales_Temp_Row.Total_Disc_Amt = (Number(Sales_Temp_Row.Disc_Amt) * Number(Sales_Temp_Row.Qty)).toFixed(2);

      Sales_Temp_Row.Final_Price = (Number(Sales_Temp_Row.Unit_Price) - Number(Sales_Temp_Row.Disc_Amt)).toFixed(2);

      var Tax_Type = "exclusive";
      try {
        Tax_Type = this.appservice.header_Row.Tax_Type;
      }
      catch {
        Tax_Type = "exclusive"
      }



      if (String(Tax_Type).toLowerCase() == "inclusive") {

        Sales_Temp_Row.Final_Price = (
          (
            ((Number(Sales_Temp_Row.Unit_Price) - Number(Sales_Temp_Row.Disc_Amt))
              /
              (100 + Number(Sales_Temp_Row.GST_Per))
            )
            * 100
          )).toFixed(2);


      }
      Sales_Temp_Row.Sub_total = (Number(Sales_Temp_Row.Final_Price) * Number(Sales_Temp_Row.Qty)).toFixed(2);
      Sales_Temp_Row.GST_Amt = ((Number(Sales_Temp_Row.Sub_total) / 100) * Number(Sales_Temp_Row.GST_Per)).toFixed(2);
      Sales_Temp_Row.IGST_Amt = ((Number(Sales_Temp_Row.Sub_total) / 100) * Number(Sales_Temp_Row.IGST_Per)).toFixed(2);
      Sales_Temp_Row.CGST_Amt = ((Number(Sales_Temp_Row.Sub_total) / 100) * Number(Sales_Temp_Row.CGST_Per)).toFixed(2);
      Sales_Temp_Row.SGST_Amt = ((Number(Sales_Temp_Row.Sub_total) / 100) * Number(Sales_Temp_Row.SGST_Per)).toFixed(2);
      Sales_Temp_Row.Net_Amt = (Math.round(Number(Sales_Temp_Row.Sub_total) + Number(Sales_Temp_Row.GST_Amt))).toFixed(2);

      this.appservice.Details_Row.push(Sales_Temp_Row);
      Sales_Temp_Row = {};


    } catch { }

  }
}



