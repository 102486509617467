import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-saleswise-profit',
  templateUrl: './saleswise-profit.component.html',
  styleUrls: ['./saleswise-profit.component.scss']
})
export class SaleswiseProfitComponent implements OnInit {

  x=6;
  constructor(private _location: Location, public appservice: AppService, private toastr: ToastrService, private http: HttpClient,  private router: Router, private route: ActivatedRoute) {
    if(this.appservice.W_width<501)
    {this.x=5;
    }
    else
    {
    this.x=0;
    }
this.appservice.get_Sales_Profit_Details();
this.appservice.cr;
this.appservice.db;
this.appservice.Total;

  }
  export_excel()
  {
 
   this.appservice.Excel_Data.Header=this.appservice.Sales_Profit_Export;
   this.appservice.Excel_Data.items=this.appservice.Sales_Profit_Row
   this.appservice.Excel_Data.Report_Name="Saleswise Profit "
   this.appservice.export_excel();
 
  }


  export_pdf(data)
 {

  this.appservice.Excel_Data.Header=this.appservice.Sales_Profit_Export;
  this.appservice.Excel_Data.Total_Row= "Total Rows : "+this.appservice.length_of(data);
  this.appservice.Excel_Data.Total_Amount="Total Amount : "+this.appservice.sum_of(data,'Amount');
  this.appservice.Excel_Data.items=this.appservice.Sales_Profit_Row
  this.appservice.Excel_Data.Report_Name="Saleswise Profit "
  this.appservice.export_pdf();
  }
  ngOnInit(): void {
  }

}
